import { useState } from "react";

import ProjectForm from "./ProjectForm";

import { Button, Modal } from "react-bootstrap";

const modalBodyStyle = {
  minHeight: "22rem",
};

const FormModal = ({
  connectionId,
  show,
  onClick,
}: {
  connectionId: string;
  show: boolean;
  onClick: () => void;
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClick}
    >
      <Modal.Header closeButton>Create Project</Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <ProjectForm connectionId={connectionId} onSubmit={onClick} />
      </Modal.Body>
    </Modal>
  );
};

const CreateProject = ({ connectionId }: { connectionId: string }) => {
  const [show, handleShow] = useState(false);
  const toggleShow = () => handleShow(!show);

  return (
    <>
      <Button className="ms-3" onClick={toggleShow}>
        Add New Project
      </Button>
      <FormModal connectionId={connectionId} show={show} onClick={toggleShow} />
    </>
  );
};

export default CreateProject;
