/**
 * @generated SignedSource<<f07e74f8dc02caa9895c94f3002404d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Taskables = "club" | "meeting" | "position" | "project" | "%future added value";
export type TaskCreateInput = {
  clientMutationId?: string | null | undefined;
  task: TaskCreateInputObject;
};
export type TaskCreateInputObject = {
  assignee?: string | null | undefined;
  description: string;
  dueDate?: any | null | undefined;
  taskableId: string;
  taskableType: Taskables;
};
export type CreateTaskForm_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: TaskCreateInput;
};
export type CreateTaskForm_Mutation$data = {
  readonly createTask: {
    readonly task: {
      readonly assignees: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
      } | null | undefined;
      readonly comments: {
        readonly totalCount: number;
      } | null | undefined;
      readonly complete: boolean;
      readonly description: string;
      readonly dueDate: any | null | undefined;
      readonly id: string;
      readonly parent: {
        readonly __typename?: string;
        readonly id?: string;
        readonly name?: string | null | undefined;
      };
    };
  };
};
export type CreateTaskForm_Mutation = {
  response: CreateTaskForm_Mutation$data;
  variables: CreateTaskForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "complete",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "CommentConnection",
  "kind": "LinkedField",
  "name": "comments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTaskForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TaskCreatePayload",
        "kind": "LinkedField",
        "name": "createTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "assignees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Avatars_users"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Assignment_Assignees"
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "type": "TaskableInterface",
                    "abstractKey": "__isTaskableInterface"
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateTaskForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TaskCreatePayload",
        "kind": "LinkedField",
        "name": "createTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "assignees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "parent",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "type": "TaskableInterface",
                    "abstractKey": "__isTaskableInterface"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Club",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Meeting",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Position",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Project",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "task",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "TaskEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d4c8d62e36e284f4ade2d300c0f56ec",
    "id": null,
    "metadata": {},
    "name": "CreateTaskForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateTaskForm_Mutation(\n  $input: TaskCreateInput!\n) {\n  createTask(input: $input) {\n    task {\n      id\n      assignees {\n        edges {\n          node {\n            id\n          }\n        }\n        ...Avatars_users\n        ...Assignment_Assignees\n      }\n      dueDate\n      complete\n      description\n      parent {\n        __typename\n        ... on TaskableInterface {\n          __isTaskableInterface: __typename\n          id\n          name\n          __typename\n        }\n        ... on Club {\n          id\n        }\n        ... on Meeting {\n          id\n        }\n        ... on Position {\n          id\n        }\n        ... on Project {\n          id\n        }\n      }\n      comments {\n        totalCount\n      }\n    }\n  }\n}\n\nfragment Assignment_Assignees on UserConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n\nfragment Avatars_users on UserConnection {\n  ...Assignment_Assignees\n  edges {\n    node {\n      id\n      ...Avatar_user\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c6163124e558b2ee85a5c6c7455b147";

export default node;
