import { FileChecksum } from "@rails/activestorage/src/file_checksum";

function calculateChecksum(file: File) {
  return new Promise<string>((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
}

export type MetaDataType = {
  checksum: string;
  filename: string;
  contentType: string;
  byteSize: number;
};

const getFileMetadata = (file: File) => {
  return new Promise<MetaDataType>((resolve) => {
    calculateChecksum(file).then((checksum: string) => {
      resolve({
        checksum,
        filename: file.name,
        contentType: file.type,
        byteSize: file.size,
      });
    });
  });
};

export default getFileMetadata;
