import { type MenuItem, DropDown } from "@/components";
import Avatars, { type Assignables } from "@/pages_old/Users/Avatars";
import { Card } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import Priority from "../Details/Priority";
import { KanbanTaskItem } from "../types";

interface TaskItemProps {
  task: KanbanTaskItem;
  options?: {
    dropDown?: MenuItem[];
    className?: string;
    assignable: {
      id: string;
      type: Assignables;
    }
  };
}

const TaskItem = ({ task, options }: TaskItemProps) => {
  const {
    dueDate,
    priority,
    url = "",
    title,
    totalComments,
    users,
    project,
  } = task;
  const { assignable, dropDown = [] } = options || {};

  return (
    <Card className="mb-0">
      <Card.Body className="p-3">
        {dueDate && <small className="float-end text-muted">{dueDate}</small>}
        <Priority priority={priority} />

        <DropDown menuItems={dropDown} >
          <i className="mdi mdi-dots-vertical font-18"></i>
        </DropDown>

        <h6 className="mt-2 mb-2">
          <Link to={url} className="text-body">
            <LinesEllipsis
              text={title || ""}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </Link>
        </h6>

        <div className="mb-0 d-flex justify-content-between">
          {project && (
            <span className="pe-2 text-nowrap mb-2 d-inline-block">
              <i className="mdi mdi-briefcase-outline text-muted"></i>
              {project}
            </span>
          )}
          {(totalComments || totalComments === 0) && (
            <span className="text-nowrap mb-2 d-inline-block">
              <i className="mdi mdi-comment-multiple-outline text-muted"></i>
              &nbsp;
              <b>{totalComments}</b>
            </span>
          )}
        
          {users && <Avatars users={users} max={2} assignable={assignable} />}
        </div>
      </Card.Body>
    </Card>
  );
};

export default TaskItem;
