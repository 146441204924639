import { Component } from "react";

import axios from "axios";
import { connect } from "react-redux";

import { DispatchType, sessionSignIn } from "../redux/actions/SessionActions";
import { signIn as signInRoute } from "../services/endpoint";

import StatusWrap, { AuthedType } from "./Session/StatusWrap";

import { loadStore } from "../redux/store/localStorage";

interface StateType {
  checkTime?: number;
}

const refreshRate = 120000; // 2 minute
const buffer = 600000; // 10 minutes

const currentSessionTimeout = () => {
  try {
    const t =
      parseInt(loadStore().sessionState?.session?.access?.expiresAt || "") ||
      Date.now() + 2 * buffer;
    return t - buffer;
  } catch (err) {
    return undefined;
  }
};

const refreshToken = () => {
  try {
    return loadStore().sessionState?.session?.refresh?.token || undefined;
  } catch (err) {
    return undefined;
  }
};

const findState = () => ({ checkTime: currentSessionTimeout() });

export type RefreshType = DispatchType & {
  onSuccess?: () => void;
  onFailure?: () => void;
};

export const fetchRefreshToken = ({
  dispatch,
  onSuccess,
  onFailure,
}: RefreshType) => {
  return async () => {
    try {
      const response = await axios.post(signInRoute(), {
        refresh_token: refreshToken(),
        grant_type: "refresh_token",
      });
      const {
        data: { access_token, expires_in, refresh_token, created_at },
      } = response;
      dispatch(
        sessionSignIn({ access_token, expires_in, refresh_token, created_at }),
      );

      onSuccess && onSuccess();
    } catch (err: unknown) {
      onFailure && onFailure();
    }
  };
};

class SessionManager extends Component<DispatchType, StateType> {
  timeId?: number;
  constructor(props: DispatchType) {
    super(props);
    this.state = findState();
    this.timeId = undefined;
  }

  componentDidMount() {
    this.timeId = window.setInterval(
      () => this.checkTokenValidity(),
      refreshRate,
    );
  }

  componentWillUnmount() {
    clearInterval(this.timeId);
  }

  checkTokenValidity() {
    const { checkTime } = this.state;
    const { dispatch } = this.props;
    const currentTime = new Date().getTime();
    if (!checkTime) {
      console.log("no check time");
      return null;
    }
    if (currentTime > checkTime) {
      return null;
    }
    fetchRefreshToken({
      dispatch,
      onSuccess: () => this.setState(findState()),
    })();
  }

  render() {
    return null;
  }
}

const MapStateToProps = (dispatch: any) => ({ dispatch });

const ConnectedSessionManager = connect(MapStateToProps)(SessionManager);

export default class WrappedConnectedSessionManager extends Component {
  render() {
    return (
      <StatusWrap>
        {({ authState }: AuthedType) => {
          if (authState === "loggedIn") {
            return <ConnectedSessionManager />;
          }
          return <></>;
        }}
      </StatusWrap>
    );
  }
}
