import { graphql } from "babel-plugin-relay/macro";
import { useToggle } from "@/hooks";
import { connect } from "react-redux";
import { createFragmentContainer } from "react-relay";

import Dropdown from "react-bootstrap/Dropdown";

import { compact, split } from "lodash";

import {
  DispatchType,
  setCurrentClub,
} from "../../redux/actions/CurrentClubActions";

import { ClubDropDown_user$data as UserType } from "./__generated__/ClubDropDown_user.graphql";

type ClubType = Exclude<
  Exclude<
    Exclude<UserType["clubs"], null | undefined>["edges"],
    null | undefined
  >[number],
  null | undefined
>;
const { loadStore } = require("../../redux/store/localStorage");

export const selectClub = ({
  dispatch,
  role,
  id,
  roleId,
  name,
  forceReload = false,
}: DispatchType & {
  role: string;
  id: string;
  roleId: string;
  forceReload?: boolean;
  name: string;
}) => {
  dispatch(
    setCurrentClub({ clubId: id, role, name, roleId: split(roleId, "--")[0] }),
  );
  forceReload && window.location.replace("/");
};

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const ClubList = ({
  dispatch,
  user,
  className = "",
}: DispatchType & { user: UserType; className?: string }) => {
  const [isOpen, toggleDropdown] = useToggle();
  const { clubs } = user;
  if (!clubs) {
    return <></>;
  }
  const edges = compact(clubs.edges || []);
  if (!edges || edges.length == 0) {
    return <></>;
  }
  const { currentClub } = getCurrentClub();
  // if(!currentClub?.id) {
  //   const edge = edges[0]
  //   if(!currentClub?.id && edge.node?.id)  {
  //     selectClub({ dispatch, role: edge.kind || '', id: edge.node.id, roleId: edge.id, name: edge.node.name || 'Club' })
  //   }
  // }

  return (
    <Dropdown show={isOpen} onToggle={toggleDropdown} className={className}>
      <Dropdown.Toggle
        variant="link"
        id="dropdown-profile"
        as={"button"}
        onClick={toggleDropdown}
        className="nav-link dropdown-toggle arrow-none nav-user px-2 py-1 rounded fw-bold"
      >
        {currentClub.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {edges.map(({ node, kind, id }: ClubType) => {
          if (!node) {
            return <></>;
          }
          return (
            <a
              onClick={() =>
                selectClub({
                  dispatch,
                  id: node.id,
                  role: kind || "",
                  forceReload: true,
                  roleId: id,
                  name: node.name || "Club",
                })
              }
              className="dropdown-item notify-item"
              key={id + "-profile-menu"}
            >
              <span>{node.name}</span>
            </a>
          );
        })}

      </Dropdown.Menu>
    </Dropdown>
  );
};

const ClubListLoader = createFragmentContainer(ClubList, {
  user: graphql`
    fragment ClubDropDown_user on User {
      id
      clubs {
        edges {
          id
          kind
          node {
            id
            name
          }
        }
      }
    }
  `,
});

const MapStateToProps = (dispatch: any) => ({ dispatch });
export default connect(MapStateToProps)(ClubListLoader);
