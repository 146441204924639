import "core-js/es";
import React, { Suspense } from "react";
import "../assets/App.scss";

import { ThemeSettings, useThemeContext } from "@/common";
import { ThemeProvider } from "@/common/context";
import { RelayEnvironmentProvider } from "react-relay";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import environment from "../Environment";

import HorizontalLayout from "@/layouts/Horizontal";
import VerticalLayout from "@/layouts/Vertical";
import ReactGA from "react-ga";
const { loadStore } = require("../redux/store/localStorage");

const authToken = () => {
  try {
    return loadStore().sessionState.session.access.token;
  } catch (err) {
    return null;
  }
};

import {
  Loading,
  LostPage,
  MeetingShowPage,
  MeetingsPage,
  PasswordReset,
  PasswordResetNew,
  PositionShowPage,
  PositionsPage,
  ProjectShowPage,
  ProjectsPage,
  SettingsPage,
  SignIn,
  TaskShowPage,
  TasksPage,
  UsersPage,
  FilesPage,
} from "./Pages";

// const createHistory = require('history').createBrowserHistory

// interface LocationType {
// 	pathname: string;
//   search: string
// }

const App: React.FC = () => {
  const gAKey = process.env.REACT_APP_GA_KEY;
  if (gAKey) {
    ReactGA.initialize(gAKey);
  }

  //   const history = createHistory()
  //   history.listen((location: LocationType, action: string) => {
  // 		if(!gAKey) { return null }
  // 		ReactGA.set({ page: location.pathname });
  // 		ReactGA.pageview(location.pathname);
  //   });

  if (!authToken()) {
    return (
      <Router>
        <RelayEnvironmentProvider environment={environment}>
          <Suspense fallback={<Loading />}>
            <ThemeProvider>
              <Routes>
                <Route
                  element={<PasswordResetNew />}
                  path="/password_reset/new"
                />
                <Route
                  element={<PasswordResetNew newUser={true} />}
                  path="/account_setup"
                />
                <Route element={<PasswordReset />} path="/password_reset/:id" />
                <Route
                  element={<PasswordReset newUser={true} />}
                  path="/account_setup/:id"
                />
                <Route element={<SignIn />} path="/*" />
              </Routes>
            </ThemeProvider>
          </Suspense>
        </RelayEnvironmentProvider>
      </Router>
    );
  }

  const { settings } = useThemeContext();
  const Layout =
    settings.layout.type == ThemeSettings.layout.type.vertical
      ? VerticalLayout
      : HorizontalLayout;

  return (
    <Router>
      <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/*" element={<Layout />}>
              <Route element={<ProjectShowPage />} path="projects/:id" />
              <Route element={<ProjectsPage />} path="projects" />
              <Route element={<PositionShowPage />} path="organisation/:id" />
              <Route element={<PositionsPage />} path="organisation" />
              <Route element={<TaskShowPage />} path="tasks/:id" />
              <Route element={<TasksPage />} path="tasks" />
              <Route element={<MeetingShowPage />} path="meetings/:id" />
              <Route element={<MeetingsPage />} path="meetings" />
              <Route element={<SettingsPage />} path="settings" />
              <Route element={<UsersPage />} path="users" />
              <Route element={<FilesPage />} path="files" />
              <Route element={<ProjectsPage />} path="" />
              <Route element={<LostPage />} path="*" />
            </Route>
          </Routes>
        </Suspense>
      </RelayEnvironmentProvider>
    </Router>
  );
};

export default App;
