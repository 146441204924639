import { useState } from "react";

import PositionForm from "./PositionForm";

import { Button, Modal } from "react-bootstrap";

const modalBodyStyle = {
  minHeight: "22rem",
};

const FormModal = ({
  connectionId,
  show,
  onClick,
}: {
  connectionId: string;
  show: boolean;
  onClick: () => void;
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onClick}
    >
      <Modal.Header closeButton>Create Position</Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <PositionForm connectionId={connectionId} onSubmit={onClick} />
      </Modal.Body>
    </Modal>
  );
};

const CreatePosition = ({ connectionId }: { connectionId: string }) => {
  const [show, handleShow] = useState(false);
  const toggleShow = () => handleShow(!show);

  return (
    <>
      <Button className="float-right mb-1 ms-3" onClick={toggleShow}>
        Add New Position
      </Button>
      <FormModal connectionId={connectionId} show={show} onClick={toggleShow} />
    </>
  );
};

export default CreatePosition;
