/**
 * @generated SignedSource<<0129f47057e2de8023c9b71bdae76d54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PositionLevel = "casual" | "committee" | "executive" | "officer" | "subcommittee" | "%future added value";
export type PositionCreateInput = {
  clientMutationId?: string | null | undefined;
  position: PositionCreateInputObject;
};
export type PositionCreateInputObject = {
  clubId: string;
  description: string;
  level?: PositionLevel | null | undefined;
  name: string;
};
export type PositionForm_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: PositionCreateInput;
};
export type PositionForm_Mutation$data = {
  readonly createPosition: {
    readonly position: {
      readonly " $fragmentSpreads": FragmentRefs<"PositionsPage_position">;
    } | null | undefined;
  };
};
export type PositionForm_Mutation = {
  response: PositionForm_Mutation$data;
  variables: PositionForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PositionForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PositionCreatePayload",
        "kind": "LinkedField",
        "name": "createPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Position",
            "kind": "LinkedField",
            "name": "position",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PositionsPage_position"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PositionForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PositionCreatePayload",
        "kind": "LinkedField",
        "name": "createPosition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Position",
            "kind": "LinkedField",
            "name": "position",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "level",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserConnection",
                "kind": "LinkedField",
                "name": "assignees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "position",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PositionEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dd1a1b552ae8b02cced0e3e9274890b",
    "id": null,
    "metadata": {},
    "name": "PositionForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation PositionForm_Mutation(\n  $input: PositionCreateInput!\n) {\n  createPosition(input: $input) {\n    position {\n      ...PositionsPage_position\n      id\n    }\n  }\n}\n\nfragment Assignment_Assignees on UserConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n\nfragment Avatars_users on UserConnection {\n  ...Assignment_Assignees\n  edges {\n    node {\n      id\n      ...Avatar_user\n    }\n  }\n}\n\nfragment PositionsPage_position on Position {\n  id\n  order\n  code\n  name\n  description\n  level\n  assignees {\n    ...Avatars_users\n    ...Assignment_Assignees\n  }\n  comments {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7bc77daa642e45e0fc1a13e0e60d30c";

export default node;
