import { PasswordInput, TextInput, useForm } from "@/components";
import { signIn as signInWrapper } from "@/pages_old/Session/SignIn";
import { useSessionStore } from "@/pages_old/Session/StatusWrap";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import AccountWrapper2 from "../AccountWrapper2";
import { loginFormSchema } from "./useLogin";

import { DispatchType } from "@/redux/actions/CurrentClubActions";

const Login = ({ dispatch }: DispatchType) => {
  const { t } = useTranslation();

  const { isAuthenticated } = useSessionStore({ dispatch });
  const [submitting, setSubmitting] = useState(false);

  const { Form, ...other } = useForm<{ email: string; password: string }>({
    onSubmit: (val) => {
      const setError = (error: string | null) => {
        other.setError("password", { message: error || "" });
      };

      signInWrapper({ setSubmitting, setStatus: setError }, { dispatch })(val);
    },
    schema: loginFormSchema,
    defaultValues: { email: "", password: "" },
  });

  return (
    <>
      {isAuthenticated && <Navigate to="/" replace />}
      <AccountWrapper2>
        <h4 className="mt-0">{t("Sign In")}</h4>
        <p className="text-muted mb-4">
          {t("Enter your email address and password to access account.")}
        </p>

        <Form>
          <TextInput
            label={t("Email Address")}
            type="email"
            name="email"
            placeholder={t("Enter your email")}
            containerClass={"mb-3"}
          />
          <PasswordInput
            label={t("Password")}
            name="password"
            placeholder={t("Enter your password")}
            containerClass={"mb-3"}
          >
            <Link to="/password_reset/new" className="text-muted float-end">
              <small>{t("Forgot your password?")}</small>
            </Link>
          </PasswordInput>

          <div className="d-grid mb-0 text-center">
            <Button variant="primary" type="submit" disabled={submitting}>
              <i className="mdi mdi-login"></i> {t("Log In")}
            </Button>
          </div>
        </Form>
      </AccountWrapper2>
    </>
  );
};

const MapStateToProps = (dispatch: any) => ({ dispatch });
export default connect(MapStateToProps)(Login);
