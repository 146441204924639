// Import redux types
import { Dispatch } from "redux";

// Import Session Typing
export interface DispatchType {
  dispatch: any;
}

// Create Action Constants
export enum CurrentClubActionTypes {
  UPDATE_CLUB = "UPDATE_CLUB",
}

interface PropsType {
  clubId?: string;
  role?: string;
  roleId?: string;
  name?: string;
}

// Interface for Get All Action Type
export interface CurrentClubUpdateActionType {
  type: CurrentClubActionTypes.UPDATE_CLUB;
  currentClub: PropsType;
}

export type CurrentClubActions = CurrentClubUpdateActionType;

export const setCurrentClub = ({ clubId, role, roleId, name }: PropsType) => {
  return async (dispatch: Dispatch) => {
    try {
      const currentClub = {
        id: clubId,
        role: role,
        roleId,
        name,
      };
      dispatch({
        currentClub,
        type: CurrentClubActionTypes.UPDATE_CLUB,
      });
    } catch (err) {
      console.error(err);
    }
  };
};
