import { Formik } from "formik";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

import {
  ProjectForm_Mutation,
  type ProjectMonth,
} from "./__generated__/ProjectForm_Mutation.graphql";

const mutation = graphql`
  mutation ProjectForm_Mutation(
    $input: ProjectCreateInput!
    $connections: [ID!]!
  ) {
    createProject(input: $input) {
      project
        @prependNode(connections: $connections, edgeTypeName: "ProjectEdge") {
        ...ProjectsPage_project
      }
    }
  }
`;

type InputType = {
  name: string;
  description: string;
  month: ProjectMonth | undefined;
  clubId: string;
};

const createProject = (project: InputType, connectionId: string) => {
  const variables = {
    input: {
      project,
    },
    connections: [connectionId],
  };

  commitMutation<ProjectForm_Mutation>(environment, {
    mutation,
    variables,
  });
};

const schema = yup.object().shape({
  name: yup.string().required("Name required"),
  description: yup.string(),
  month: yup.string(),
});

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const ProjectForm = ({
  connectionId,
  onSubmit,
}: {
  connectionId: string;
  onSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  return (
    <Formik
      initialValues={{ name: "", description: "", month: undefined }}
      onSubmit={({ name, description, month }, actions) => {
        createProject({ clubId, month, name, description }, connectionId);
        onSubmit();
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                type="name"
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="description" className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                type="description"
                as="textarea"
                rows={5}
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="month" className="mt-2">
              <Form.Label>Month</Form.Label>
              <Form.Select
                className="d-block"
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.month}
              >
                <option value={undefined}>---</option>
                {map(months, (month) => (
                  <option key={month} value={month}>
                    {t(`month.${month}`)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="mt-2"
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default ProjectForm;
