/**
 * @generated SignedSource<<e699806abe4d7b5aa7185079a9055776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShowPageTask_task$data = {
  readonly assignees: {
    readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
  } | null | undefined;
  readonly complete: boolean;
  readonly dueDate: any | null | undefined;
  readonly id: string;
  readonly name: string | null | undefined;
  readonly parent: {
    readonly __typename?: string;
    readonly id?: string;
    readonly name?: string | null | undefined;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CommentFeed_comments">;
  readonly " $fragmentType": "ShowPageTask_task";
};
export type ShowPageTask_task$key = {
  readonly " $data"?: ShowPageTask_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowPageTask_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowPageTask_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "assignees",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatars_users"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Assignment_Assignees"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "complete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentFeed_comments"
        }
      ],
      "type": "CommentableInterface",
      "abstractKey": "__isCommentableInterface"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "type": "TaskableInterface",
          "abstractKey": "__isTaskableInterface"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "01cc734fb470228da5868b99206a96e7";

export default node;
