import { graphql } from "babel-plugin-relay/macro";
import Dropzone from "react-dropzone";
import { useMutation } from "react-relay";
import useFileUploader from "./useFileUploader";

import { FileUploader_CreateMutation } from "./__generated__/FileUploader_CreateMutation.graphql";

export type FileType = File & {
  preview?: string;
  formattedSize?: string;
};

const mutation = graphql`
  mutation FileUploader_CreateMutation(
    $input: FileLinkCreateInput!
    $connections: [ID!]!
  ) {
    createFileLink(input: $input) {
      fileLink
        @appendNode(connections: $connections, edgeTypeName: "FileTypeEdge") {
        id
        link
        downloadUrl {
          url
          id
          expiresAt
        }
        name
      }
    }
  }
`;

type FileUploaderProps = {
  showPreview?: boolean;
  linkable: { id: string; type: string };
  categories: {
    id: string;
    code: string | null | undefined;
    name: string | null | undefined;
  }[];
};

const FileUploader = ({ showPreview = true }: FileUploaderProps) => {
  const { selectedFiles, handleAcceptedFiles, removeFile } =
    useFileUploader(showPreview);
  const [commit] = useMutation<FileUploader_CreateMutation>(mutation);

  return (
    <>
      <Dropzone onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone">
            <div className="dz-message needsclick" {...getRootProps()}>
              <input {...getInputProps()} />
              <i className="h1 text-muted ri-upload-cloud-2-line"></i>
              <h3>Drop files here or click to upload.</h3>
              <span className="text-muted font-13">
                (This is just a demo dropzone. Selected files are
                <strong>not</strong> actually uploaded.)
              </span>
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
};

export { FileUploader };
