import Login from "@/pagesExample/account/Login2";
import { Component } from "react";

class MainPage extends Component {
  render() {
    return <Login />;
  }
}

export default MainPage;
