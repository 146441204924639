/**
 * @generated SignedSource<<3412e1a8f08a5d730bd6ed1aa54ae1da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Attachments_FileType$data = {
  readonly edges: ReadonlyArray<{
    readonly fileLinks: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly byteSize: number | null | undefined;
          readonly contentType: string | null | undefined;
          readonly downloadUrl: {
            readonly expiresAt: any;
            readonly id: string;
            readonly url: string;
          } | null | undefined;
          readonly id: string;
          readonly link: string | null | undefined;
          readonly name: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly totalCount: number;
    };
    readonly node: {
      readonly code: string | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CreateFileModal_FileTypes">;
  readonly " $fragmentType": "Attachments_FileType";
};
export type Attachments_FileType$key = {
  readonly " $data"?: Attachments_FileType$data;
  readonly " $fragmentSpreads": FragmentRefs<"Attachments_FileType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Attachments_FileType",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateFileModal_FileTypes"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SuggestedFileTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FileLinkConnection",
          "kind": "LinkedField",
          "name": "fileLinks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FileLinkEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FileLink",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "link",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ExpiringDownload",
                      "kind": "LinkedField",
                      "name": "downloadUrl",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "expiresAt",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "contentType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "byteSize",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "ClientExtension",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id",
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FileType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SuggestedFileTypeConnection",
  "abstractKey": null
};
})();

(node as any).hash = "4d5a6472dbe08ef6e2fc979876456bac";

export default node;
