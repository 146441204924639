import { CardTitle, type MenuItem } from "@/components";
import DescriptionForm, { type ConfigType, type OnToggleLockType } from "@/pages_old/Projects/DescriptionForm";
export { type OnToggleLockType } from "@/pages_old/Projects/DescriptionForm";
import Avatars, { type Assignables } from "@/pages_old/Users/Avatars";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Moment from "moment";
import { forwardRef, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { ListTaskItem } from "../types";
import Checklist from "./Checklist";
import Priority from "./Priority";
import { type Taskables } from '@/pages_old/Tasks/CreateTaskForm';

export type UpdateDescriptionType = (s: string, config: ConfigType) => void;

[
  { label: "Attachment", icon: "uil uil-file-upload" },
  { label: "Edit", icon: "uil uil-edit" },
  { label: "Mark as Duplicate", icon: "uil uil-file-copy-alt" },
  {
    label: "Delete",
    icon: "uil uil-trash-alt",
    variant: "text-danger",
    hasDivider: true,
  },
];

export type TaskProps = {
  task: ListTaskItem;
  taskType?: Taskables;
  dropdowns?: MenuItem[];
  header?: React.ReactNode;
  updateDate?: (d: Date) => void;
  updateDescription?: UpdateDescriptionType;
  onToggleLock?: OnToggleLockType;
  showTime?: boolean;
  usersTitle?: string;
  dateTitle?: string;
  linkTitle?: string;
};

const Task = ({
  usersTitle = 'Assigned To',
  dateTitle = 'Due Date',
  linkTitle = 'See tools, training, and templates',
  updateDescription,
  updateDate,
  header,
  task,
  dropdowns = [],
  onToggleLock,
  taskType,
  showTime = false,
}: TaskProps) => {
  const { description, users, priority, tasks, link } = task;
  const [descriptionEdit, toggleDescriptionEdit] = useState(true);

  {
    /* const contentState = ContentState.createFromText(description || ''); */
  }
  {
    /* {/1* const state = EditorState.createWithContent(contentState) *1/} */
  }
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const raw = convertToRaw(editorState.getCurrentContent());
  const descriptionHtml = sanitizeHtml(draftToHtml(raw));

  const ExampleCustomInput = forwardRef<HTMLInputElement, {}>(
    ({ value, onClick }: any, ref) => (
      <span onClick={onClick} className="d-flex">
        <i className="uil uil-schedule font-18 text-success me-1"></i>
        <div>
          <h5 className="mt-1 font-14">
            {Moment(task.dueDate).format(showTime ? "LLL" : "ll")}
          </h5>
        </div>
      </span>
    ),
  );

  return (
    <Card>
      <Card.Body>
        <CardTitle
          containerClass="d-flex align-items-center justify-content-between"
          title={
            <div className="float-start">
              {task.title && (
                <h3 className="mt-3 d-inline me-2">{task.title}</h3>
              )}
              {priority && <Priority priority={priority} />}
              {link && (
                <Button className="d-block mt-2" href={link} target="_blank">
                  {linkTitle}
                </Button>
              )}
              {header}
            </div>
          }
          icon="mdi mdi-dots-horizontal"
          menuItems={dropdowns}
        />
        <div className="clearfix"></div>
        <Row>
          <Col>
            <Row>
              <Col>
                <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">
                   {usersTitle}
                </p>
                {users && (
                  <div className="d-flex">
                    <Avatars users={users} assignable={taskType ? { id: task.id, type: taskType as Assignables} : undefined} />
                  </div>
                )}
              </Col>

              {task.dueDate && updateDate && (
                <Col>
                  <p className="mt-2 mb-1 text-muted fw-bold font-12 text-uppercase">
                    {dateTitle}
                  </p>
                  <div className="d-flex">
                    <DatePicker
                      selected={Moment(task.dueDate).toDate()}
                      onChange={updateDate}
                      customInput={<ExampleCustomInput />}
                      showTimeSelect={showTime}
                    />
                  </div>
                </Col>
              )}
            </Row>
            {updateDescription && (
              <>
                <h5 className="mt-3">Overview:</h5>
              {true && <div>
                  {descriptionEdit ? (
                    <DescriptionForm description={description} id={task.id} onUpdate={updateDescription} locked={task.locked} onToggleLock={onToggleLock} />
                  ) : (
                    <p
                      dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                      className="text-muted mb-4"
                    />
                  )}
                  {false && (
                    <div onClick={() => toggleDescriptionEdit(!descriptionEdit)}>
                      <i className="mdi mdi-pencil me-1" />
                      Edit
                    </div>
                  )}
                </div>}
              </>
            )}

            {tasks && (
              <>
                <h5 className="mt-3">Tasks:</h5>
                <Checklist tasks={tasks} taskable={taskType ? { id: task.id, type: taskType} : undefined} />
              </>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Task;
