/**
 * @generated SignedSource<<52d7bf1e1912c6940b9a28242653be01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SettingsPage_Query$variables = Record<PropertyKey, never>;
export type SettingsPage_Query$data = {
  readonly viewer: {
    readonly currentUser: {
      readonly id: string;
      readonly name: string;
      readonly optInEmailReminders: boolean;
      readonly partyPooper: boolean;
    } | null | undefined;
  };
};
export type SettingsPage_Query = {
  response: SettingsPage_Query$data;
  variables: SettingsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "partyPooper",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optInEmailReminders",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsPage_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettingsPage_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "73572a9bcffc913fccc48bd29fac72c2",
    "id": null,
    "metadata": {},
    "name": "SettingsPage_Query",
    "operationKind": "query",
    "text": "query SettingsPage_Query {\n  viewer {\n    currentUser {\n      id\n      name\n      partyPooper\n      optInEmailReminders\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "347521730f677de15d2e7bc89350bbe6";

export default node;
