import { Formik } from "formik";
import Moment from "moment";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  CreateTaskForm_Mutation,
  Taskables as TaskablesImport,
} from "./__generated__/CreateTaskForm_Mutation.graphql";

export type Taskables = TaskablesImport;

const mutation = graphql`
  mutation CreateTaskForm_Mutation(
    $input: TaskCreateInput!
    $connections: [ID!]!
  ) {
    createTask(input: $input) {
      task @prependNode(connections: $connections, edgeTypeName: "TaskEdge") {
        ...Tasks_TaskNode @relay(mask: false)
      }
    }
  }
`;

interface PropsType {
  connectionId: string;
  taskable: {
    id: string;
    type: Taskables;
  };
  onSubmit: () => void;
}

type InputType = {
  taskable: {
    id: string;
    type: Taskables;
  };
  description: string;
  dueDate: string;
};

const createTask = (
  { description, dueDate, taskable }: InputType,
  connectionId: string,
) => {
  var validDueDate: string | null = dueDate;
  if (validDueDate === "") {
    validDueDate = null;
  }
  const variables = {
    input: {
      task: {
        taskableId: taskable.id,
        taskableType: taskable?.type,
        description,
        dueDate: validDueDate,
      },
    },
    connections: [connectionId],
  };

  commitMutation<CreateTaskForm_Mutation>(environment, {
    mutation,
    variables,
  });
};

const schema = yup.object().shape({
  description: yup.string().required("Task description required"),
  dueDate: yup.string(),
});

const ProjectForm = ({ connectionId, taskable, onSubmit }: PropsType) => {
  return (
    <Formik
      initialValues={{
        dueDate: Moment().format("YYYY-MM-DD"),
        description: "",
      }}
      onSubmit={({ dueDate, description }) => {
        createTask(
          { dueDate: Moment(dueDate).toISOString(), description, taskable },
          connectionId,
        );
        onSubmit();
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                type="description"
                placeholder="eg: Send email to contacts"
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="dueDate" className="mt-2">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dueDate}
                type="date"
                placeholder="Due Date"
                isValid={touched.dueDate && !errors.dueDate}
                isInvalid={touched.dueDate && !!errors.dueDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dueDate}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="mt-2"
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default ProjectForm;
