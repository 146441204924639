import { indexOf } from "lodash";
import { useState } from "react";

// copied from pages/tasks query
type OrderKeys = "createdAt" | "dueDate" | "%future added value";
type OrderValues = "asc" | "desc" | "%future added value";
type OrderType = {
  key: OrderKeys;
  value: OrderValues;
};

export const useTaskOrdering = () => {
  const [order, setOrder] = useState<OrderType[]>([
    { key: "dueDate", value: "asc" },
  ]);

  const toggleOrder = (input: OrderKeys | undefined) => {
    const { key, value } = order[0] || {};
    if (input) {
      if (key === input) {
        const list: (OrderValues | null)[] = ["asc", "desc", null];
        const nextInd = (indexOf(list, value) + 1) % 3;
        const next = list[nextInd];

        if (next === null) {
          setOrder([]);
        } else {
          setOrder([{ key: input, value: next }]);
        }
      } else {
        setOrder([{ key: input, value: "asc" }]);
      }
    }
  };

  return { order, toggleOrder };
};
