import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import ProjectsShowPage from "../Projects/ShowPage";

import { ProjectShowPageQuery as ProjectShowPageQueryType } from "./__generated__/ProjectShowPageQuery.graphql";

const ProjectsPageQuery = graphql`
  query ProjectShowPageQuery($id: ID!) {
    project(id: $id) {
      ...ShowPageProject_project
    }
  }
`;

export default function ProjectsPageLoader() {
  const { id } = useParams();

  if (!id) return <></>;
  const { project } = useLazyLoadQuery<ProjectShowPageQueryType>(
    ProjectsPageQuery,
    { id: id },
  );

  if (project) {
    return <ProjectsShowPage project={project} />;
  }

  return <>Project unknown</>;
}
