/**
 * @generated SignedSource<<df1ff6656123701e356c257f58be3740>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FilesPageQuery$variables = {
  id?: string | null | undefined;
};
export type FilesPageQuery$data = {
  readonly club: {
    readonly files: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly byteSize: number | null | undefined;
          readonly contentType: string | null | undefined;
          readonly createdAt: any;
          readonly downloadUrl: {
            readonly expiresAt: any;
            readonly id: string;
            readonly url: string;
          } | null | undefined;
          readonly fileType: {
            readonly code: string | null | undefined;
            readonly id: string;
            readonly name: string | null | undefined;
            readonly owners: {
              readonly nodes: ReadonlyArray<{
                readonly __typename?: string;
                readonly id?: string;
                readonly name?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly link: string | null | undefined;
          readonly name: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type FilesPageQuery = {
  response: FilesPageQuery$data;
  variables: FilesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpiringDownload",
  "kind": "LinkedField",
  "name": "downloadUrl",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "byteSize",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v12 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "alias": "files",
            "args": null,
            "concreteType": "FileLinkConnection",
            "kind": "LinkedField",
            "name": "fileLinks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileLinkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileLink",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileType",
                        "kind": "LinkedField",
                        "name": "fileType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileTypeFileLinkableConnection",
                            "kind": "LinkedField",
                            "name": "owners",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/),
                                      (v5/*: any*/),
                                      (v9/*: any*/)
                                    ],
                                    "type": "FileLinkableInterface",
                                    "abstractKey": "__isFileLinkableInterface"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "alias": "files",
            "args": null,
            "concreteType": "FileLinkConnection",
            "kind": "LinkedField",
            "name": "fileLinks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileLinkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileLink",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileType",
                        "kind": "LinkedField",
                        "name": "fileType",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileTypeFileLinkableConnection",
                            "kind": "LinkedField",
                            "name": "owners",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "nodes",
                                "plural": true,
                                "selections": [
                                  (v9/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/),
                                      (v5/*: any*/)
                                    ],
                                    "type": "FileLinkableInterface",
                                    "abstractKey": "__isFileLinkableInterface"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v12/*: any*/),
                                    "type": "Position",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": (v12/*: any*/),
                                    "type": "Project",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v10/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "390f25d4c085a1cda825b4ec074ca514",
    "id": null,
    "metadata": {},
    "name": "FilesPageQuery",
    "operationKind": "query",
    "text": "query FilesPageQuery(\n  $id: ID\n) {\n  club(id: $id) {\n    files: fileLinks {\n      edges {\n        node {\n          id\n          link\n          downloadUrl {\n            id\n            url\n            expiresAt\n          }\n          name\n          contentType\n          byteSize\n          createdAt\n          fileType {\n            owners {\n              nodes {\n                __typename\n                ... on FileLinkableInterface {\n                  __isFileLinkableInterface: __typename\n                  id\n                  name\n                  __typename\n                }\n                ... on Position {\n                  id\n                }\n                ... on Project {\n                  id\n                }\n              }\n            }\n            id\n            code\n            name\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a89645ed6e1d63ee669bfdbf878675c1";

export default node;
