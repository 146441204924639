const { loadStore } = require("../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

export const permissions = () => {
  const { currentClub } = getCurrentClub();
  const { role } = currentClub || {};

  const admin = role === "admin";
  const coordinator = role === "coordinator";
  const viewer = role === "general";

  const data = {
    project: {
      update: admin || coordinator,
      create: admin || coordinator,
      view: viewer,
    },
    comment: {
      create: viewer,
    },
    like: {
      create: viewer,
    },
    position: {
      update: admin || coordinator,
      create: admin || coordinator,
      view: viewer,
    },
    fileLink: {
      update: admin || coordinator,
      create: admin || coordinator,
      delete: admin,
      view: viewer,
    },
    task: {
      update: admin || coordinator,
      create: admin || coordinator,
      delete: admin || coordinator,
      view: viewer,
    },
    meeting: {
      update: admin || coordinator,
      create: admin || coordinator,
      view: viewer,
      unlock: admin,
      hide: admin,
    },
    assignment: {
      update: admin || coordinator,
      create: admin || coordinator,
      view: viewer,
    },
    membership: {
      view: admin,
      create: admin,
    },
  };

  return data;
};
