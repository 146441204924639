import { PageBreadcrumb } from "@/components";
import prettyBytes from "pretty-bytes";
import { graphql } from "babel-plugin-relay/macro";
import { compact, map } from "lodash";
import { useLazyLoadQuery } from "react-relay";
import { FilesPageQuery } from './__generated__/FilesPageQuery.graphql'
import Recent from '@/pagesExample/apps/FileManager/Recent';
import removeFileLink from "@/pages_old/Files/RemoveFileLink";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const query = graphql`
  query FilesPageQuery(
    $id: ID
  ) {
    club(id: $id) {
      files: fileLinks {
        __id
        edges {
          node {
            id
            link
            downloadUrl {
              id
              url
              expiresAt
            }
            name
            contentType
            byteSize
            createdAt
            fileType {
              owners {
                nodes {
                  ... on FileLinkableInterface {
                    id
                    name
                    __typename
                      
                  }
                }
              }
              id
              code
              name
            }
          }
        }
      }
    }
  }
`;

export default function FilesPage() {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  const { club } = useLazyLoadQuery<FilesPageQuery>(query, {
    id: clubId,
  });

  if (!club || !club.files) return <></>;

  const files = compact(
    map(club.files.edges || [], (edge) => {
      if (edge && edge.node) {
        const {
          id,
          name,
          createdAt,
          byteSize,
          link,
          downloadUrl,
          fileType,
          contentType,
        } = edge.node;

        return {
          id,
          name,
          modifiedDate: createdAt,
          size: byteSize ? prettyBytes(byteSize) : undefined,
          link: link || downloadUrl?.url,
          type: fileType?.name ,
          owners: compact(fileType?.owners?.nodes || []),
          contentType,
        };
      }
    }),
  );

  const onDelete = (id: string) => {
    removeFileLink(id, club?.files?.__id || '')
  }

  return (
    <>
      <div className="page-title-box">
        <PageBreadcrumb title={
          <>Files</>
        }>
        </PageBreadcrumb>
      </div>

      <Recent recentFiles={files} onDelete={onDelete} />
    </>
  );
}
