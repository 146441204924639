/**
 * @generated SignedSource<<40d53606a8bcc204d108843c7ad8275d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Assignables = "meeting" | "position" | "project" | "task" | "%future added value";
export type AssignMutation$variables = {
  assignableId: string;
  assignableType: Assignables;
  assigneeId?: string | null | undefined;
  connections: ReadonlyArray<string>;
};
export type AssignMutation$data = {
  readonly assignCreate: {
    readonly assignee: {
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
    };
  };
};
export type AssignMutation = {
  response: AssignMutation$data;
  variables: AssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignableId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignableType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assigneeId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assignableId",
            "variableName": "assignableId"
          },
          {
            "kind": "Variable",
            "name": "assignableType",
            "variableName": "assignableType"
          },
          {
            "kind": "Variable",
            "name": "assigneeId",
            "variableName": "assigneeId"
          }
        ],
        "kind": "ObjectValue",
        "name": "assign"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AssignCreatePayload",
        "kind": "LinkedField",
        "name": "assignCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignee",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Avatar_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AssignCreatePayload",
        "kind": "LinkedField",
        "name": "assignCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignee",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "assignee",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "UserEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e384b3426b5cc39a0f804f9871f1a6d",
    "id": null,
    "metadata": {},
    "name": "AssignMutation",
    "operationKind": "mutation",
    "text": "mutation AssignMutation(\n  $assigneeId: ID\n  $assignableId: ID!\n  $assignableType: Assignables!\n) {\n  assignCreate(input: {assign: {assigneeId: $assigneeId, assignableId: $assignableId, assignableType: $assignableType}}) {\n    assignee {\n      id\n      name\n      ...Avatar_user\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n"
  }
};
})();

(node as any).hash = "42633b6e3b78e93dd25620a065bbd467";

export default node;
