const typeToRoute = (name: string): string | undefined => {
  switch (name) {
    case "Project":
      return "/projects/";
    case "Position":
      return "/organisation/";
    case "Meeting":
      return "/meetings/";
  }
};

export default typeToRoute;
