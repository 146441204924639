/**
 * @generated SignedSource<<0eb82f55376726648256e91f33d3a693>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MembershipRoleKindEnumType = "admin" | "coordinator" | "general" | "%future added value";
export type MembershipCreateInput = {
  clientMutationId?: string | null | undefined;
  invite: MembershipCreateInputObject;
};
export type MembershipCreateInputObject = {
  clubId: string;
  email: string;
  name?: string | null | undefined;
  role?: MembershipRoleKindEnumType | null | undefined;
};
export type InviteForm_Mutation$variables = {
  connections: ReadonlyArray<string>;
  input: MembershipCreateInput;
};
export type InviteForm_Mutation$data = {
  readonly inviteUser: {
    readonly user: {
      readonly email: string;
      readonly id: string;
      readonly name: string;
    };
  };
};
export type InviteForm_Mutation = {
  response: InviteForm_Mutation$data;
  variables: InviteForm_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MembershipCreatePayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteForm_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MembershipCreatePayload",
        "kind": "LinkedField",
        "name": "inviteUser",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "user",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "ClubUserEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87000ba466bd78bc95c32e09e57162ba",
    "id": null,
    "metadata": {},
    "name": "InviteForm_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteForm_Mutation(\n  $input: MembershipCreateInput!\n) {\n  inviteUser(input: $input) {\n    user {\n      id\n      email\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fa579aba67dc4fc27b60e8e3ed9d6d1f";

export default node;
