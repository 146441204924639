import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface PropsType<T> {
  setFilter: (filter: T | null) => void;
  filter: T | null;
  filters: (T | null)[];
}

function Filters<T>({ filters, setFilter, filter }: PropsType<T>) {
  const { t } = useTranslation();
  const basic = (filters[0] === filter);

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={`input-group-text btn ${basic ? "btn-secondary" : "btn-primary"}`}
        type="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="uil uil-sort-amount-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu align={'end'}>
        {filters.map((f) => (
          <Dropdown.Item
            active={f == filter}
            key={String(f)}
            onClick={() => {
              setFilter(f);
            }}
          >
            {t(`filter.${f}`)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Filters;
