// Import Reducer type
import { Reducer } from "redux";
import {
  CurrentClubActionTypes,
  CurrentClubActions,
} from "../actions/CurrentClubActions";

// Define the Session type
export interface CurrentClubType {
  id?: string;
  role?: string;
}

// Define the Session State
export interface CurrentClubStateType {
  readonly currentClub: CurrentClubType;
}

// Define the initial state
const initialCurrentClubState: CurrentClubStateType = {
  currentClub: {},
};

export const currentClubReducer: Reducer<
  CurrentClubStateType,
  CurrentClubActions
> = (state = initialCurrentClubState, action) => {
  switch (action.type) {
    case CurrentClubActionTypes.UPDATE_CLUB: {
      return {
        currentClub: action.currentClub,
      };
    }
    default:
      return state;
  }
};
