import { type AppDispatch } from "@/redux";
import { fetchRefreshToken } from "@pages/SessionManager";
import { useState } from "react";
import { connect } from "react-redux";

import { DispatchType } from "../../redux/actions/CurrentClubActions";

type AuthEnum = "loggedOut" | "loggedIn" | "Pending";
export interface AuthedType {
  authState: AuthEnum;
}

const { loadStore } = require("@/redux/store/localStorage");

const sessionState = () => {
  try {
    return loadStore().sessionState || { session: undefined };
  } catch (err) {
    return { session: undefined };
  }
};

interface PropsType {
  children: ({ authState }: AuthedType) => JSX.Element;
}

export const useSessionStore = ({ dispatch }: { dispatch: AppDispatch }) => {
  const [authState, updateAuthState] = useState<AuthEnum>("loggedOut");
  const { session } = sessionState();

  var accessToken;
  var expiresAt;
  var refreshToken;
  if (session) {
    accessToken = session.access.token;
    expiresAt = session.access.expiresAt;
    refreshToken = session.refresh.refreshToken;
  }

  const unixNow = Date.now();
  if (
    authState !== "Pending" &&
    expiresAt &&
    parseInt(expiresAt) >= unixNow &&
    refreshToken
  ) {
    fetchRefreshToken({ dispatch });
    updateAuthState("Pending");
  }
  if (
    authState !== "loggedIn" &&
    accessToken &&
    expiresAt &&
    parseInt(expiresAt) < unixNow
  ) {
    updateAuthState("loggedIn");
  }

  return {
    authState,
    isAuthenticated: authState === "loggedIn",
  };
};

const StatusWrap = ({ dispatch, children }: PropsType & DispatchType) => {
  const { authState } = useSessionStore({ dispatch });

  return <>{children({ authState })}</>;
};

const MapStateToProps = (dispatch: any) => ({ dispatch });
export default connect(MapStateToProps)(StatusWrap);
