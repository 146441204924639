import { useState } from "react";
import { Button, Modal, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { permissions } from "../../services/permissions";
import InviteForm from "./InviteForm";

const modalBodyStyle = {
  minHeight: "22rem",
};

export const InviteModal = ({
  clubId,
  connectionId,
  className = ''
}: {
  clubId: string;
  connectionId: string;
  className?: string;
}) => {
  const [show, setShow] = useState(false);
  const {
    membership: { create: membershipCreate },
  } = permissions();

  const { t } = useTranslation();
  if (!membershipCreate) {
    return <></>;
  }

  return (
    <>
      <Button onClick={() => setShow(true)} className={`float-right mb-1 mr-1 ${className}`}>
        {t("membership.new")}
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Tab.Container defaultActiveKey="signIn">
          <Modal.Header closeButton>Invite new user</Modal.Header>
          <Modal.Body style={modalBodyStyle}>
            <InviteForm
              connectionId={connectionId}
              clubId={clubId}
              onSubmit={() => setShow(false)}
            />
          </Modal.Body>
        </Tab.Container>
      </Modal>
    </>
  );
};

export default InviteModal;
