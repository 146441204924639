import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";

const Search = ({
  setSearch,
}: {
  setSearch: (search: string | null) => void;
}) => {
  let timeout: ReturnType<typeof setTimeout>;
  const onSubmit = ({ search }: { search: string | null }) => {
    setSearch(search);
    clearTimeout(timeout)
  }
  return (
    <Formik<{ search: string }>
      initialValues={{ search: "" }}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        submitForm,
        resetForm,
        values,
      }) => {
        const clear = () => {
          resetForm();
          submitForm();
        };
        return (
          <Form onSubmit={handleSubmit} className="d-flex pb-1">
            <Form.Group controlId="search">
              <Form.Control
                onChange={(v) => {
                  handleChange(v)
                  if (timeout) clearTimeout(timeout);
                  timeout = setTimeout(() => {
                    onSubmit({ search: v.target.value });
                  }, 400);
                }}
                onBlur={handleBlur}
                value={values.search}
                placeholder="Search..."
              ></Form.Control>
            </Form.Group>
            {values.search.length > 0 ? (
              <i onClick={clear} className="bi bi-x ps-2 pt-2"></i>
            ) : (
              <></>
            )}
          </Form>
        );
      }}
    />
  );
};

export default Search;
