import { Formik } from "formik";
import { map } from "lodash";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  MeetingKindEnum,
  UpdateMeetingForm_Mutation,
} from "./__generated__/UpdateMeetingForm_Mutation.graphql";
export { type UpdateMeetingForm_Mutation } from "./__generated__/UpdateMeetingForm_Mutation.graphql";

export const mutation = graphql`
  mutation UpdateMeetingForm_Mutation($input: MeetingUpdateInput!) {
    updateMeeting(input: $input) {
      meeting {
        id
        name
        link
        description
        date
        kind
      }
    }
  }
`;

interface PropsType {
  meeting: InputType;
  onSubmit: () => void;
}

type InputType = {
  name?: string | null;
  link?: string | null;
  id: string;
  date?: string | null;
  kind?: MeetingKindEnum | null;
};

export const updateMeeting = (
  { name, link, date, kind, id }: InputType,
  config?: { onCompleted: () => void },
) => {
  var validDueDate: string | null | undefined = date;
  if (validDueDate === "") {
    validDueDate = null;
  }
  const variables = {
    input: {
      meeting: {
        id,
        name,
        link,
        kind,
        date: validDueDate,
      },
    },
  };

  commitMutation<UpdateMeetingForm_Mutation>(environment, {
    mutation,
    variables,
    onCompleted: config?.onCompleted,
  });
};

const kinds: MeetingKindEnum[] = ["agm", "commitee", "other"];

const schema = yup.object().shape({
  name: yup.string().required("Meeting name required"),
  link: yup.string().url().nullable(),
  kind: yup.string().required("Meeting kind required").oneOf(kinds),
  date: yup.string().required("Date is required"),
});

const MeetingForm = ({ meeting, onSubmit }: PropsType) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={meeting}
      onSubmit={(val) => {
        updateMeeting(val, { onCompleted: onSubmit });
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name || ""}
                type="text"
                placeholder="eg: Dec 2024 Committee Meeting"
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.link || ""}
                type="text"
                placeholder="Zoom or Google meet link."
                isValid={touched.link && !errors.link}
                isInvalid={touched.link && !!errors.link}
              />
              <Form.Control.Feedback type="invalid">
                {errors.link}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="date" className="mt-2">
              <Form.Label>Date</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={Moment(values.date).format("YYYY-MM-DD")}
                type="date"
                placeholder="Due Date"
                isValid={touched.date && !errors.date}
                isInvalid={touched.date && !!errors.date}
              />
              <Form.Control.Feedback type="invalid">
                {errors.date}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="kind" className="mt-2">
              <Form.Label>Meeting Type</Form.Label>
              <div></div>
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.kind || ""}
              >
                <option value={undefined}>---</option>
                {map(kinds, (kind) => (
                  <option key={kind} value={kind}>
                    {t(`meeting.name.${kind}`)}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid" className="d-block">
                {errors.kind}
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              className="mt-2"
              variant="primary"
              type="submit"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default MeetingForm;
