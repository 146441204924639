import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  type DraggableStateSnapshot,
} from "react-beautiful-dnd";

export {
  type DropResult,
} from "react-beautiful-dnd";
import { Col, Row } from "react-bootstrap";
import { find, sortBy as sortByFunction } from 'lodash';

export type MinimumColumnType = {
  name: string;
  filterKey: string | null;
};

type MinimumTableRequirements = {
  id: string;
};

type PropsType<TaskType, ColumnType> = {
  onDragEnd: (task: TaskType | undefined, col: ColumnType | undefined, res: DropResult) => void;
  handleDateChange: (date: Date) => void;
  // TODO: remove any
  handleNewTask: (vals: any) => void;
  columns: ColumnType[];
  data: TaskType[];
  children: (ret: { item: TaskType, dragData: DraggableStateSnapshot }) => React.ReactElement;
  groupBy?: keyof TaskType;
  sortBy?: keyof TaskType;
  className?: string;
  headerRenderer?: (rendData: {
    column: ColumnType;
    data: TaskType[];
  }) => React.ReactNode;
};

function KanbanTask<
  TaskType extends MinimumTableRequirements,
  ColumnType extends MinimumColumnType
>(props: PropsType<TaskType, ColumnType>) {
  const {
    children,
    columns,
    data: unsortedData,
    onDragEnd: rawOnDragEnd,
    groupBy = "id",
    sortBy,
    className = "",
    headerRenderer = ({ column }) => column.name,
  } = props;
  const data = sortBy ? sortByFunction(unsortedData, sortBy) : unsortedData;
  const dataForColumn = (column: ColumnType): TaskType[] => {
    return data.filter((t) => {
      return t[groupBy] === (column.filterKey);
    });
  };

  const onDragEnd = (res: DropResult) => {
    const { destination, draggableId } = res
    const task = find(data, ['id', draggableId]);
    const column = find(columns, ['filterKey', destination?.droppableId])

    if(task && destination) {
      rawOnDragEnd(task, column, res)
    }
  }

  return (
    <>
      <Row>
        <Col>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="board d-flex">
              {columns.map((column) => (
                <Droppable
                  key={column.name}
                  droppableId={column.filterKey || column.name}
                >
                  {(provided) => (
                    <div
                      className={`tasks ${className}`}
                      ref={provided.innerRef}
                    >
                      <h5 className="mt-0 task-header">
                        {headerRenderer({
                          column,
                          data: dataForColumn(column),
                        })}
                      </h5>

                      {dataForColumn(column).length === 0 && (
                        <p className="text-center text-muted pt-2 mb-0">
                          No Tasks
                        </p>
                      )}

                      {dataForColumn(column).map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id + ""}
                          index={index}
                        >
                          {(provided, dragData) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {children({ item, dragData })}
                            </div>
                          )
                          }
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        </Col>
      </Row>
    </>
  );
}

export { KanbanTask };
export default KanbanTask;
