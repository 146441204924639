import { ToastContainer as TC, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastError = ({ message }: { message: string }) => {
  toast.error(message, {
    autoClose: 5_000,
    pauseOnHover: true,
    hideProgressBar: false,
    closeOnClick: true,
  });
};

export const ToastContainer = TC;

export default () => ({ toastError });
