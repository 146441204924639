import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import PositionsShowPage from "../Positions/ShowPage";

import { PositionShowPageQuery as PositionShowPageQueryType } from "./__generated__/PositionShowPageQuery.graphql";

const PositionsPageQuery = graphql`
  query PositionShowPageQuery($id: ID!) {
    position(id: $id) {
      ...ShowPagePosition_position
    }
  }
`;

export default function PositionsPageLoader() {
  const { id } = useParams();

  if (!id) return <></>;
  const { position } = useLazyLoadQuery<PositionShowPageQueryType>(
    PositionsPageQuery,
    { id: id },
  );

  if (position) {
    return <PositionsShowPage position={position} />;
  }

  return <>Position unknown</>;
}
