/**
 * @generated SignedSource<<29231933ec35314efd50737564d01643>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadcreateMutation$variables = {
  byteSize: number;
  checksum: string;
  contentType: string;
  filename: string;
};
export type UploadcreateMutation$data = {
  readonly createUpload: {
    readonly directUpload: {
      readonly blobId: string;
      readonly headers: string;
      readonly signedBlobId: string;
      readonly url: string;
    };
  };
};
export type UploadcreateMutation = {
  response: UploadcreateMutation$data;
  variables: UploadcreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "byteSize"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checksum"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filename"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "byteSize",
            "variableName": "byteSize"
          },
          {
            "kind": "Variable",
            "name": "checksum",
            "variableName": "checksum"
          },
          {
            "kind": "Variable",
            "name": "contentType",
            "variableName": "contentType"
          },
          {
            "kind": "Variable",
            "name": "filename",
            "variableName": "filename"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreatePayload",
    "kind": "LinkedField",
    "name": "createUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DirectUpload",
        "kind": "LinkedField",
        "name": "directUpload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blobId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signedBlobId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadcreateMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadcreateMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "2fad40c536bf4890dea9d5381d2031a8",
    "id": null,
    "metadata": {},
    "name": "UploadcreateMutation",
    "operationKind": "mutation",
    "text": "mutation UploadcreateMutation(\n  $filename: String!\n  $byteSize: Int!\n  $contentType: String!\n  $checksum: String!\n) {\n  createUpload(input: {filename: $filename, byteSize: $byteSize, contentType: $contentType, checksum: $checksum}) {\n    directUpload {\n      blobId\n      headers\n      signedBlobId\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "535d24ad59b66e50be00c19df7bc5321";

export default node;
