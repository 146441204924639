import { Form } from 'react-bootstrap';
import { ThemeSettings, useThemeContext } from "@/common";
import useThemeCustomizer from "@/components/ThemeCustomizer/useThemeCustomizer";

const DisplaySettings = () => {
  const { settings, updateSettings, updateSidebar } = useThemeContext();
  const { handleChangeLayoutType } = useThemeCustomizer()

  /**
   * Toggle Dark Mode
   */
  const toggleDarkMode = () => {
    if (settings.theme === "dark") {
      updateSettings({ theme: ThemeSettings.theme.light });
    } else {
      updateSettings({ theme: ThemeSettings.theme.dark });
    }
  };

  const toggleMenuPosition = () => {
    if (settings.layout.type === ThemeSettings.layout.type.horizontal) {
      handleChangeLayoutType(ThemeSettings.layout.type.vertical);
    } else {
      handleChangeLayoutType(ThemeSettings.layout.type.horizontal);
    }
  }


  return(
    <>
      <Form>
        <Form.Check
          type="switch"
          id="darkMode"
          label="Light Mode / Dark Mode"
          checked={settings.theme === 'dark'}
          onChange={toggleDarkMode}
        />

        <Form.Check
          type="switch"
          id="menuPosition"
          label="Vertical Menu / Horizontal Menu"
          checked={settings.layout.type === ThemeSettings.layout.type.horizontal}
          onChange={toggleMenuPosition}
        />

      </Form>
    </>
  )
}

export default DisplaySettings;
