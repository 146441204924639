import { type FormEvent } from 'react';
import { Formik, type FormikHelpers } from "formik";
import { permissions } from "../../services/permissions";

import { graphql } from "babel-plugin-relay/macro";
import { Alert, Button, Form } from "react-bootstrap";
import { commitMutation } from "react-relay";

import environment from "../../Environment";
import { useRichEditor, type EditorState } from "../General";

import { DescriptionForm_ProjectMutation } from "./__generated__/DescriptionForm_ProjectMutation.graphql";

const mutation = graphql`
  mutation DescriptionForm_ProjectMutation($input: ProjectUpdateInput!) {
    updateProject(input: $input) {
      project {
        ...ProjectsPage_project
      }
    }
  }
`;

export type ConfigType = FormikHelpers<{ description: EditorState }>;

type InputType = {
  id: string;
  description?: string | null;
  completed?: boolean | null;
};

export type OnToggleLockType = (s: string) => void;
type ActionType = { setSubmitting: (st: boolean) => void };

export const updateProject = (project: InputType, config?: { onCompleted?: () => void }) => {
  const variables = {
    input: {
      project,
    },
  };

  commitMutation<DescriptionForm_ProjectMutation>(environment, {
    mutation,
    variables,
    onCompleted: config?.onCompleted,
  });
};

function DescriptionForm(props: {
  description: string | undefined | null;
  id: string;
  onUpdate?: (description: string, config: ConfigType) => void;
  onToggleLock?: OnToggleLockType;
  lockButtonText?: string;
  locked?: boolean | null;
}) {
  let timeout: ReturnType<typeof setTimeout>;
  const { stateToString, stringToState, RichEditor } = useRichEditor();
  const { onUpdate, onToggleLock, lockButtonText= 'Lock and Email'  } = props

  const onSubmit = ({ description }: { description: EditorState }, actions: ConfigType) => {
    if(onUpdate) {
      onUpdate(stateToString(description), actions)
      return 
    }

    updateProject(
      {
        description: stateToString(description),
        id: props.id,
      },
      {
        onCompleted: () => actions.setSubmitting(false),
      },
    );
  }

  return (
    <Formik
      initialValues={{ description: stringToState(props.description || "") }}
      onSubmit={onSubmit}
      render={(actions) => {
        const {
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          status,
          isSubmitting,
        } = actions

        const {
          project: { update: projectUpdate },
        } = permissions();

        return (
          <>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="description" className="pb-1">
                <RichEditor
                  name="description"
                  readOnly={!!props.locked}
                  onChange={(state) => {
                    if (timeout) clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      onSubmit({ description: state }, actions);
                    }, 2000);
                  }}
                />
              </Form.Group>

              {!props.locked && projectUpdate && (
                <Button
                  className="float-right mr-1"
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              )}

              {onToggleLock && (props.locked ? (
                <Button
                  className="float-right mr-1"
                  variant="secondary"
                  onClick={() => onToggleLock(stateToString(values.description))}
                  disabled={isSubmitting}
                >
                  Unlock
                </Button>
              ) : (
                <>
                  <Button
                    className="float-right mr-1"
                    variant="secondary"
                    onClick={() => onToggleLock(stateToString(values.description))}
                    disabled={isSubmitting}
                  >
                    {lockButtonText}
                  </Button>
                </>
              ))}

              <div style={{ paddingTop: "1rem" }}>
                {!!status && <Alert variant="danger">{status}</Alert>}
              </div>
            </Form>
          </>
        );
      }}
    />
  );
};

export default DescriptionForm;
