/**
 * @generated SignedSource<<a979f830ae8645e5f575d74ebcc69358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomePage_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RightNav_viewer">;
  readonly " $fragmentType": "HomePage_viewer";
};
export type HomePage_viewer$key = {
  readonly " $data"?: HomePage_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomePage_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomePage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RightNav_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "94426e84f2840aab82600d36c0673a3a";

export default node;
