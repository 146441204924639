/**
 * @generated SignedSource<<135a0dfff208a2681951966746f94ad2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraggableCard_users$data = {
  readonly nodes: ReadonlyArray<{
    readonly id: string;
  } | null | undefined> | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
  readonly " $fragmentType": "DraggableCard_users";
};
export type DraggableCard_users$key = {
  readonly " $data"?: DraggableCard_users$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraggableCard_users">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraggableCard_users",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Avatars_users"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Assignment_Assignees"
    }
  ],
  "type": "UserConnection",
  "abstractKey": null
};

(node as any).hash = "4f1b96ef565d7b8cad258c0c4f591547";

export default node;
