import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import TasksShowPage from "../Tasks/ShowPage";

import { TaskShowPageQuery as TaskShowPageQueryType } from "./__generated__/TaskShowPageQuery.graphql";

const TasksPageQuery = graphql`
  query TaskShowPageQuery($id: ID!) {
    task(id: $id) {
      ...ShowPageTask_task
    }
  }
`;

export default function TasksPageLoader() {
  const { id } = useParams();

  if (!id) return <></>;
  const { task } = useLazyLoadQuery<TaskShowPageQueryType>(TasksPageQuery, {
    id: id,
  });

  if (task) {
    return <TasksShowPage task={task} />;
  }

  return <>Task unknown</>;
}
