import { PageBreadcrumb } from "@/components";
import CreateMeetingModal from "@/pages_old/Meetings/CreateMeetingModal";
import { updateMeeting } from "@/pages_old/Meetings/UpdateMeetingForm";
import { permissions } from "@/services/permissions";
import { graphql } from "babel-plugin-relay/macro";
import { compact, indexOf, map } from "lodash";
import Moment from "moment";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import ListTask, { type ListTaskItem } from "@/pagesExample/apps/tasks/List";
import { Filters, Search } from "../Table";

import {
  ProjectFilter as MeetingsFilter,
  MeetingsPageQuery,
  OrderKeys,
  OrderType,
  OrderValues,
} from "./__generated__/MeetingsPageQuery.graphql";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

const filters: (MeetingsFilter | null)[] = [null, "mine"];

const query = graphql`
  query MeetingsPageQuery(
    $id: ID
    $filter: ProjectFilter
    $search: String
    $order: [OrderType!]
  ) {
    club(id: $id) {
      meetings(filter: $filter, search: $search, order: $order) {
        __id
        edges {
          node {
            id
            hidden: private
            assignees {
              totalCount
              ...Avatars_users
              ...Assignment_Assignees
            }
            comments {
              totalCount
            }
            date
            description
            name
            kind
            tasks {
              ...Checklist_TaskConnection
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default function MeetingsPageLoader() {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;
  const {
    meeting: { create: meetingCreate },
  } = permissions();

  const [filter, setFilter] = useState<MeetingsFilter | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const [order, setOrder] = useState<OrderType[]>([
    { key: "dueDate", value: "desc" },
  ]);
  const toggleOrder = (input: OrderKeys | undefined) => {
    const { key, value } = order[0] || {};
    if (input) {
      if (key === input) {
        const list: (OrderValues | null)[] = ["asc", "desc", null];
        const nextInd = (indexOf(list, value) + 1) % 3;
        const next = list[nextInd];
        if (next === null) {
          setOrder([]);
        } else {
          setOrder([{ key: input, value: next }]);
        }
      } else {
        setOrder([{ key: input, value: "asc" }]);
      }
    }
  };

  const { club } = useLazyLoadQuery<MeetingsPageQuery>(query, {
    id: clubId,
    search,
    filter,
    order,
  });

  if (!club) return <></>;

  const meetings = compact(
    map(club.meetings.edges || [], (edge) => {
      if (edge && edge.node) {
        const {
          id,
          name: title,
          comments,
          date: dueDate,
          assignees: users,
          tasks,
          hidden
        } = edge.node;

        return {
          id,
          title,
          link: `/meetings/${id}`,
          comments,
          dueDate,
          users,
          tasks,
          hidden
        };
      }
    }),
  );

  console.log(meetings)

  const updateDate = (date: Date | null, { id }: ListTaskItem) => {
    if (date) {
      updateMeeting({ id, date: Moment(date).toISOString() });
    }
  };

  return (
    <>
      <div className="page-title-box">
        <PageBreadcrumb title={
            <>
              Meetings
              {meetingCreate && (
                <CreateMeetingModal
                  connectionId={club.meetings.__id}
                  className="ms-3"
                />
              )}
            </>
          }
        >
          <div className="page-title-right">
            <div className='input-group'>
              <Search setSearch={setSearch} />
              <Filters {...{ setFilter, filter, filters }} />
            </div>
          </div>

        </PageBreadcrumb>
      </div>
      <ListTask
        sections={[{ id: "meetings", tasks: meetings }]}
        collapsable={false}
        type="meeting"
        onUpdateDate={updateDate}
        showTime={true}
      />
    </>
  );
}
