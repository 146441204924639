import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";
import {
  UpdateMembership_Mutation,
  type MembershipRoleKindEnumType,
} from "./__generated__/UpdateMembership_Mutation.graphql";

const mutation = graphql`
  mutation UpdateMembership_Mutation($input: MembershipUpdateInput!) {
    updateMembership(input: $input) {
      membership {
        id
        kind
      }
    }
  }
`;

interface PropsType {
  id: string;
  role?: MembershipRoleKindEnumType | undefined;
  onError?: (e: Error) => void | null;
  onCompleted?: () => void;
}

const removeMembership = ({ id, role, onError, onCompleted }: PropsType) => {
  if (window.confirm("Are you sure you want to update this membership?")) {
    const variables = {
      input: {
        membership: {
          id,
          kind: role,
        },
      },
    };

    commitMutation<UpdateMembership_Mutation>(environment, {
      mutation,
      variables,
      onError,
      onCompleted,
    });
  }
};

export default removeMembership;
