/**
 * @generated SignedSource<<60dda347e9ff75584902bc45afc42437>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeetingKindEnum = "agm" | "commitee" | "other" | "%future added value";
export type OrderKeys = "createdAt" | "dueDate" | "order" | "%future added value";
export type OrderValues = "asc" | "desc" | "%future added value";
export type ProjectFilter = "completed" | "incompleted" | "mine" | "overdue" | "unassigned" | "%future added value";
export type OrderType = {
  key: OrderKeys;
  value: OrderValues;
};
export type MeetingsPageQuery$variables = {
  filter?: ProjectFilter | null | undefined;
  id?: string | null | undefined;
  order?: ReadonlyArray<OrderType> | null | undefined;
  search?: string | null | undefined;
};
export type MeetingsPageQuery$data = {
  readonly club: {
    readonly meetings: {
      readonly __id: string;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly assignees: {
            readonly totalCount: number;
            readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
          } | null | undefined;
          readonly comments: {
            readonly totalCount: number;
          } | null | undefined;
          readonly date: any | null | undefined;
          readonly description: string | null | undefined;
          readonly hidden: boolean | null | undefined;
          readonly id: string;
          readonly kind: MeetingKindEnum | null | undefined;
          readonly name: string | null | undefined;
          readonly tasks: {
            readonly totalCount: number;
            readonly " $fragmentSpreads": FragmentRefs<"Checklist_TaskConnection">;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type MeetingsPageQuery = {
  response: MeetingsPageQuery$data;
  variables: MeetingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "hidden",
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "CommentConnection",
  "kind": "LinkedField",
  "name": "comments",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEdge",
  "kind": "LinkedField",
  "name": "edges",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MeetingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "MeetingConnection",
            "kind": "LinkedField",
            "name": "meetings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Meeting",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "assignees",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Avatars_users"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Assignment_Assignees"
                          }
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskConnection",
                        "kind": "LinkedField",
                        "name": "tasks",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Checklist_TaskConnection"
                          },
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MeetingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Club",
        "kind": "LinkedField",
        "name": "club",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "MeetingConnection",
            "kind": "LinkedField",
            "name": "meetings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeetingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Meeting",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "assignees",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v15/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskConnection",
                        "kind": "LinkedField",
                        "name": "tasks",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Task",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": "users",
                                    "args": null,
                                    "concreteType": "UserConnection",
                                    "kind": "LinkedField",
                                    "name": "assignees",
                                    "plural": false,
                                    "selections": [
                                      (v15/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "dueDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "complete",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "title",
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "description",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "parent",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v6/*: any*/),
                                          (v12/*: any*/)
                                        ],
                                        "type": "TaskableInterface",
                                        "abstractKey": "__isTaskableInterface"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v16/*: any*/),
                                        "type": "Club",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v16/*: any*/),
                                        "type": "Meeting",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v16/*: any*/),
                                        "type": "Position",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": (v16/*: any*/),
                                        "type": "Project",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v9/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v8/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fc52ce4d2c6b6a02979a5219c15baf00",
    "id": null,
    "metadata": {},
    "name": "MeetingsPageQuery",
    "operationKind": "query",
    "text": "query MeetingsPageQuery(\n  $id: ID\n  $filter: ProjectFilter\n  $search: String\n  $order: [OrderType!]\n) {\n  club(id: $id) {\n    meetings(filter: $filter, search: $search, order: $order) {\n      edges {\n        node {\n          id\n          hidden: private\n          assignees {\n            totalCount\n            ...Avatars_users\n            ...Assignment_Assignees\n          }\n          comments {\n            totalCount\n          }\n          date\n          description\n          name\n          kind\n          tasks {\n            ...Checklist_TaskConnection\n            totalCount\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Assignment_Assignees on UserConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n\nfragment Avatar_user on User {\n  id\n  name\n  email\n  imageUrl\n}\n\nfragment Avatars_users on UserConnection {\n  ...Assignment_Assignees\n  edges {\n    node {\n      id\n      ...Avatar_user\n    }\n  }\n}\n\nfragment Checklist_TaskConnection on TaskConnection {\n  edges {\n    node {\n      id\n      users: assignees {\n        edges {\n          node {\n            id\n          }\n        }\n        ...Avatars_users\n        ...Assignment_Assignees\n      }\n      dueDate\n      complete\n      title: description\n      parent {\n        __typename\n        ... on TaskableInterface {\n          __isTaskableInterface: __typename\n          id\n          name\n          __typename\n        }\n        ... on Club {\n          id\n        }\n        ... on Meeting {\n          id\n        }\n        ... on Position {\n          id\n        }\n        ... on Project {\n          id\n        }\n      }\n      comments {\n        totalCount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5786fcdfff8e491c815ec1624733e9f5";

export default node;
