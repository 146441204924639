import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  UserUpdateInputObject as InputType,
  UpdateUserMutation,
} from "./__generated__/UpdateUserMutation.graphql";

const mutation = graphql`
  mutation UpdateUserMutation($input: UserUpdateInput!) {
    updateUser(input: $input) {
      user {
        id
        name
        partyPooper
        optInEmailReminders
      }
    }
  }
`;

export default (user: InputType) => {
  const variables = {
    input: { user },
  };

  commitMutation<UpdateUserMutation>(environment, {
    mutation,
    variables,
  });
};
