import { graphql } from "babel-plugin-relay/macro";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { createFragmentContainer } from "react-relay";

import { Avatar_user$data as AvatarType } from "./__generated__/Avatar_user.graphql";
export { type Avatar_user$key as AvatarType } from "./__generated__/Avatar_user.graphql";

interface PropsType {
  hover?: boolean;
  className?: string;
  user: AvatarType;
  onClick?: () => void;
  size?: number;
  showName?: boolean;
}

type ColourType = {
  background: string,
  border?: string 
  color?: string 
}

const colours: ColourType[] = [
  { background: 'rgb(31, 33, 72)', color: 'white' },
  { background: 'rgb(40, 65, 144)', color: 'white' },
  { background: 'rgb(54, 118, 181)', color: 'white' },
  { background: 'rgb(88, 168, 219)', color: 'rgb(31, 33, 72)' },
  { background: 'rgb(82, 166, 158)', color: 'rgb(31, 33, 72)' },
  { background: 'rgb(99, 179, 114)', color: 'rgb(31, 33, 72)' },
  { background: 'rgb(158, 195, 85)', color: 'rgb(31, 33, 72)' },
  { background: 'white', border: '2px solid rgb(31, 33, 72)', color: 'rgb(31, 33, 72)' },
  { background: 'rgb(114, 127, 236)', border: '2px solid rgb(31, 33, 72)', color: 'rgb(31, 33, 72)' },
  { background: 'white', border: '2px solid rgb(158, 195, 85)', color: 'rgb(158, 195, 85)' },
]

const getInitials = (name: string) => {
  return name
    .split(" ")
    .slice(0, 2)
    .map((i) => i[0])
    .join("")
    .toUpperCase();
};

var styleFromString = (str: string) => {
  const index = parseInt(parseInt(str, 36).toExponential().slice(2, -5), 10) % colours.length

  return colours[index]
}

const Avatar = ({
  user: { id, name, email, imageUrl },
  onClick,
  size = 45,
  className = "",
  showName = false,
  hover = true,
}: PropsType) => {
  if (!hover) {
    if (imageUrl) {
      return (
        <>
          <img
            src={imageUrl}
            alt={name}
            className="avatar-xs rounded-circle me-1"
          />
          {showName ? (
            <span className="align-middle">{getInitials(name)}</span>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return (
        <span
          onClick={onClick}
          className={`${className} avatar-inner`}
          style={styleFromString(id)}
        >
          {imageUrl ? imageUrl : getInitials(name)}
        </span>
      );
    }
  }
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 100, hide: 400 }}
      overlay={(props) => (
        <Tooltip id={`tooltip-${name}`} {...props}>
          {name || email}
        </Tooltip>
      )}
    >
      {imageUrl ? (
        <>
          <img
            src={imageUrl}
            alt={name}
            className="avatar-xs rounded-circle me-1"
          />
          {showName ? (
            <span className="align-middle">{getInitials(name)}</span>
          ) : (
            <></>
          )}
        </>
      ) : (
        <span
          onClick={onClick}
          className={`${className} avatar-inner avatar-inner-hover`}
          style={styleFromString(id)}
        >
          {imageUrl ? imageUrl : getInitials(name)}
        </span>
      )}
    </OverlayTrigger>
  );
};

export default createFragmentContainer(Avatar, {
  user: graphql`
    fragment Avatar_user on User {
      id
      name
      email
      imageUrl
    }
  `,
});
