import { Formik } from "formik";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import updateUser from "./UpdateUser";

const schema = yup.object().shape({
  name: yup.string().required("Name required"),
});

interface PropsType {
  user: {
    id: string;
    name: string;
    partyPooper: boolean;
    optInEmailReminders: boolean;
  };
}

export default ({
  user: { id, name, partyPooper, optInEmailReminders },
}: PropsType) => {
  return (
    <Formik
      validationSchema={schema}
      initialValues={{ name, partyPooper, optInEmailReminders }}
      onSubmit={({ name, partyPooper, optInEmailReminders }) => {
        updateUser({ id, name, partyPooper, optInEmailReminders });
      }}
      render={({
        touched,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
                value={values.name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Check
              className="mt-2"
              type="checkbox"
              id="optInEmailReminders"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Receive reminder emails."
              checked={values.optInEmailReminders}
            />

            <Form.Check
              className="mt-2"
              type="checkbox"
              id="partyPooper"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Party Pooper (No confetti on project complete)"
              checked={values.partyPooper}
            />

            <Button className="mt-2" type="submit">Save</Button>
          </Form>
        );
      }}
    />
  );
};
