import axios from "axios";
import {
  DispatchType,
  sessionSignIn,
} from "../../redux/actions/SessionActions";
import { signIn as signInRoute } from "../../services/endpoint";

import { selectClub } from "@/pages_old/NavBar/ClubDropDown";

import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";

import {
  SignInRefreshUserMutation,
  type SignInRefreshUserMutation$data as MutationData,
} from "./__generated__/SignInRefreshUserMutation.graphql";

interface SignInType {
  email: string;
  password: string;
}

const mutation = graphql`
  mutation SignInRefreshUserMutation {
    queryRefresh(input: {}) {
      viewer {
        user: currentUser {
          clubs(count: 1) {
            edges {
              kind
              id
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const signIn = (
  actions: {
    setSubmitting: (b: boolean) => void;
    setStatus: (s: string | null) => void;
  },
  { dispatch }: DispatchType,
) => {
  return async ({ email, password }: SignInType) => {
    try {
      actions.setSubmitting(true);
      actions.setStatus("");
      const response = await axios.post(signInRoute(), {
        email,
        password,
        grant_type: "password",
      });
      const {
        data: { access_token, expires_in, refresh_token, created_at },
      } = response;

      await sessionSignIn({
        access_token,
        expires_in,
        refresh_token,
        created_at,
      })(dispatch);

      const onCompleted = (d: MutationData) => {
        const memberships = d.queryRefresh.viewer?.user?.clubs?.edges || [];
        const membership = memberships[0];
        if (
          membership &&
          membership.kind &&
          membership.node &&
          membership.node.id &&
          membership.node.name
        ) {
          selectClub({
            dispatch,
            roleId: membership.id,
            role: membership.kind,
            id: membership.node.id,
            name: membership.node.name,
            forceReload: true,
          });
        }
      };

      const onError = (d: Error) => {
        actions.setSubmitting(false);
        console.log("error", d);
      };

      commitMutation<SignInRefreshUserMutation>(environment, {
        variables: {},
        mutation,
        onCompleted,
        onError,
      });
    } catch (err: unknown) {
      console.log("e", err);
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 400) {
          actions.setStatus("Incorrect Email or Password");
        } else {
          actions.setStatus(
            `Unknown error occurred: ${err.message} -- ${err.code}`,
          );
        }
      } else {
        console.log(err);
      }
      actions.setSubmitting(false);
    }
  };
};
