const endpoint = () => {
  return process.env.REACT_APP_ENDPOINT || "unknown";
};

export const graphqlEndpoint = () => `${endpoint()}graphql`;

export const signIn = () => `${endpoint()}oauth/token`;

export const signOut = () => `${endpoint()}oauth/revoke`;

export const imageUrl = (path: string) => `${endpoint()}${path}`;
