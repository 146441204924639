import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";
import { UnlockMeetings_Mutation } from "./__generated__/UnlockMeetings_Mutation.graphql";

const mutation = graphql`
  mutation UnlockMeetings_Mutation($id: ID!) {
    unlockMeeting(input: { id: $id }) {
      meeting {
        ...ShowPageMeeting_meeting
      }
    }
  }
`;

const unlockMeeting = (id: string) => {
  commitMutation<UnlockMeetings_Mutation>(environment, {
    mutation,
    variables: { id },
  });
};

export default unlockMeeting;
