/**
 * @generated SignedSource<<f33ce6d442346be633592ba6fa85e249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PasswordResetRequestInput = {
  accountSetup?: boolean | null | undefined;
  clientMutationId?: string | null | undefined;
  email: string;
};
export type PasswordResetRequestMutation$variables = {
  input: PasswordResetRequestInput;
};
export type PasswordResetRequestMutation$data = {
  readonly passwordResetRequest: {
    readonly status: boolean | null | undefined;
  };
};
export type PasswordResetRequestMutation = {
  response: PasswordResetRequestMutation$data;
  variables: PasswordResetRequestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PasswordResetRequestPayload",
    "kind": "LinkedField",
    "name": "passwordResetRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordResetRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordResetRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be2e631690362437be62ac71175981ed",
    "id": null,
    "metadata": {},
    "name": "PasswordResetRequestMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordResetRequestMutation(\n  $input: PasswordResetRequestInput!\n) {\n  passwordResetRequest(input: $input) {\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "436b6bd4ed07686c2714379222cd8205";

export default node;
