import { yupResolver } from "@hookform/resolvers/yup";
import { CSSProperties, ReactNode } from "react";
import { Form as BSForm } from "react-bootstrap";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm as useFormProvider,
} from "react-hook-form";
import { ObjectSchema } from "yup";

type FormProps<TFormValues extends FieldValues> = {
  id?: string;
  name?: string;
  schema?: ObjectSchema<TFormValues>;
  onSubmit: SubmitHandler<TFormValues>;
  children: ReactNode;
  defaultValues?: DefaultValues<TFormValues>;
  className?: string;
  styles?: CSSProperties;
};

type UseFormProps<TFormValues extends FieldValues> = {
  id?: string;
  name?: string;
  schema?: ObjectSchema<TFormValues>;
  onSubmit: SubmitHandler<TFormValues>;
  defaultValues?: DefaultValues<TFormValues>;
  className?: string;
  styles?: CSSProperties;
};

export const useForm = <
  TFormValues extends Record<string, any> = Record<string, any>,
>({
  schema,
  onSubmit,
  defaultValues,
  ...props
}: UseFormProps<TFormValues>) => {
  const methods = useFormProvider<TFormValues>({
    // @ts-ignore
    resolver: schema != null ? yupResolver<TFormValues>(schema) : undefined,
    defaultValues,
    mode: "onChange",
  });

  const Form = ({ children }: { children: ReactNode }) => (
    <FormProvider {...methods}>
      <BSForm onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </BSForm>
    </FormProvider>
  );

  return { Form, ...methods };
};

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  schema,
  onSubmit,
  children,
  defaultValues,
  ...props
}: FormProps<TFormValues>) => {
  const methods = useFormProvider<TFormValues>({
    // @ts-ignore
    resolver: schema != null ? yupResolver<TFormValues>(schema) : undefined,
    defaultValues,
    mode: "onChange",
  });

  return (
    <FormProvider {...methods}>
      <BSForm onSubmit={methods.handleSubmit(onSubmit)} {...props}>
        {children}
      </BSForm>
    </FormProvider>
  );
};

export default Form;
