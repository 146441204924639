import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";
import { RemoveFileLink_Mutation } from "./__generated__/RemoveFileLink_Mutation.graphql";

const mutation = graphql`
  mutation RemoveFileLink_Mutation(
    $input: FileLinkDeleteInput!
    $connections: [ID!]!
  ) {
    removeFileLink(input: $input) {
      deletedFileLinkIds @deleteEdge(connections: $connections)
    }
  }
`;

const removeFileLink = (id: string, connectionId: string) => {
  if (window.confirm("Are you sure you want to delete this file link")) {
    const variables = {
      input: {
        ids: [id],
      },
      connections: [connectionId],
    };

    commitMutation<RemoveFileLink_Mutation>(environment, {
      mutation,
      variables,
    });
  }
};

export default removeFileLink;
