import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { Card } from "react-bootstrap";
import LinesEllipsis from "react-lines-ellipsis";
import { createFragmentContainer } from "react-relay";
import { Link } from "react-router-dom";
import Assign from "../Assign";
import { Assignables } from "../Assign/__generated__/AssignMutation.graphql";
import Draggable from "../Table/Draggable";
import Avatars from "../Users/Avatars";
import { DraggableCard_users$data as UsersType } from "./__generated__/DraggableCard_users.graphql";

interface PropsType {
  id: string;
  disabled?: boolean;
  link?: string;
  name: string;
  children?: JSX.Element;
  assignees?: UsersType | null | undefined;
  checked?: boolean;
  onCheckClick?: (event: React.MouseEvent<HTMLElement>) => void;
  assignableType: Assignables;
}

const DraggableCard = ({
  id,
  disabled = false,
  link = "/",
  name,
  children = <></>,
  assignees,
  checked,
  onCheckClick,
  assignableType,
}: PropsType) => {
  return (
    <div key={id} style={{ margin: "auto", width: "150px" }}>
      <Draggable
        key={id}
        id={id}
        disabled={disabled}
        className="p-0 my-1 rounded w-100"
      >
        <Card>
          <Card.Body className="pt-0 my-1 w-100 px-1">
            <LinesEllipsis
              text={name || ""}
              maxLine="3"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
            <div
              className="flex justify-content-between mx-2 mt-1 border p-1 background-gray dnd-kit-no-drag"
              data-no-dnd="true"
            >
              <div>
                {assignees && (
                  <Assign
                    assignable={{ id, type: assignableType }}
                    assignees={assignees}
                  >
                    {(toggleShow) => (
                      <Avatars
                        onClick={toggleShow}
                        max={2}
                        users={assignees}
                        overlap={true}
                        iconOnEmpty
                      />
                    )}
                  </Assign>
                )}
              </div>
              {checked !== undefined ? (
                <div onClick={onCheckClick}>
                  <i
                    className={`pl-1 bi bi-${checked ? "check" : "x"}-square`}
                  />
                </div>
              ) : (
                <></>
              )}
              <div>
                <Link to={link}>
                  <i className="bi bi-box-arrow-up-right"></i>
                </Link>
              </div>
            </div>
            {children}
          </Card.Body>
        </Card>
      </Draggable>
    </div>
  );
};

export default createFragmentContainer(DraggableCard, {
  assignees: graphql`
    fragment DraggableCard_users on UserConnection {
      nodes {
        id
      }
      ...Avatars_users
      ...Assignment_Assignees
    }
  `,
});
