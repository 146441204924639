import classNames from "classnames";
import { type PriorityType } from "../types";

export default ({ priority }: { priority?: PriorityType | null }) => {
  if (!priority) {
    return <></>;
  }
  return (
    <span
      className={classNames("badge", {
        "bg-danger": priority.key === "High",
        "bg-secondary": priority.key === "Medium",
        "bg-success": priority.key === "Low",
      })}
    >
      {priority.name}
    </span>
  );
};
