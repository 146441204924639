import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import { PasswordResetRequestMutation } from "./__generated__/PasswordResetRequestMutation.graphql";

interface PasswordResetRequestType {
  email: string;
  accountSetup?: boolean;
}

const mutation = graphql`
  mutation PasswordResetRequestMutation($input: PasswordResetRequestInput!) {
    passwordResetRequest(input: $input) {
      status
    }
  }
`;

const passwordResetRequest = (
  input: PasswordResetRequestType,
  {
    onSuccess,
  }: {
    onSuccess: () => void;
  },
) => {
  const variables = { input };

  commitMutation<PasswordResetRequestMutation>(environment, {
    mutation,
    variables,
    onCompleted: onSuccess,
  });
};

export default passwordResetRequest;
