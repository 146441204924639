import { useState } from "react";

import PositionsPage from "../Positions/PositionsPage";

import { Filters, Search } from "../Table";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

import { ProjectFilter as PositionFilter } from "../Positions/__generated__/PositionsPageQuery.graphql";

const filters: (PositionFilter | null)[] = [null, "unassigned", "mine"];

export default function PositionsPageLoader() {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  const [filter, setFilter] = useState<PositionFilter | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  return (
    <>
      {clubId && (
        <PositionsPage
          clubId={clubId}
          setFilter={setFilter}
          filter={filter}
          search={search}
        >
          <div className="page-title-right">
            <div className='input-group'>
              <Search setSearch={setSearch} />
              <Filters<PositionFilter> {...{ setFilter, filters, filter }} />
            </div>
          </div>
        </PositionsPage>
      )}
    </>
  );
}
