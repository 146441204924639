import { BlobUpload } from "@rails/activestorage/src/blob_upload";

export const directUpload = (url: string, headers: any, file: File) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } });
  return new Promise<void>((resolve, reject) => {
    upload.create((error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export default directUpload;
