import { useAccountLayout } from "@/components/BGCircles";
import { ReactNode } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// images
import Logo from "@/assets/images/logo.png";

import CricketCelebration from "@/assets/images/Cricket-celebration.jpg";

type AccountLayoutProps = {
  bottomLinks?: ReactNode;
  children?: ReactNode;
};

const AccountWrapper2 = ({ bottomLinks, children }: AccountLayoutProps) => {
  useAccountLayout();
  const { t } = useTranslation();

  return (
    <div
      className="auth-fluid"
      style={{ backgroundImage: `url(${CricketCelebration})` }}
    >
      <div className="auth-fluid-form-box">
        <Card.Body className="d-flex flex-column h-100 gap-3">
          <div className="auth-brand text-center text-lg-start d-flex">
            <Link to="/" className="logo-dark m-auto">
              <span>
                <img src={Logo} alt="" height={100} />
              </span>
            </Link>
          </div>

          <div className="my-auto">{children}</div>

          {bottomLinks}

          <footer className="footer footer-alt">
            2022 - {new Date().getFullYear()} © -{" "}
            <Link to="https://prosportcoach.com/" target="_blank">
              ProSportCoach
            </Link>
          </footer>
        </Card.Body>
      </div>

      <div className="auth-fluid-right text-center">
        <div className="auth-user-testimonial">
          <h2 className="mb-3">{t("It's a great system!")}</h2>
          <p className="lead">
            <i className="mdi mdi-format-quote-open"></i>
            {t(
              "Getting this database including calendar, policies and governance in one place is fantastic.",
            )}
            <i className="mdi mdi-format-quote-close"></i>
          </p>
          <p>{t("- Michael Davis, Wattle Park Football Club")}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountWrapper2;
