import { Formik } from "formik";
import { map, reduce } from "lodash";
import { useState } from "react";
import * as yup from "yup";
import uploadFile from "../Upload";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useMutation } from "react-relay";
import { useToast } from "../General";

import {
  CreateFileForm_Mutation,
  FileLinkables,
} from "./__generated__/CreateFileForm_Mutation.graphql";
export { type FileLinkables } from "./__generated__/CreateFileForm_Mutation.graphql";

const mutation = graphql`
  mutation CreateFileForm_Mutation(
    $input: FileLinkCreateInput!
    $connections: [ID!]!
  ) {
    createFileLink(input: $input) {
      fileLink
        @appendNode(connections: $connections, edgeTypeName: "FileTypeEdge") {
        id
        link
        downloadUrl {
          url
          id
          expiresAt
        }
        name
        contentType
        byteSize
      }
    }
  }
`;

interface EdgeType {
  fileLinks:
    | {
        __id: string;
      }
    | null
    | undefined;
  node:
    | {
        id: string;
        name: string | null | undefined;
      }
    | null
    | undefined;
}

interface PropsType {
  edges: readonly EdgeType[];
  fileLinkable: {
    id: string;
    type: FileLinkables;
  };
  onSubmit?: () => void;
}

const schema = yup.object().shape({
  name: yup.string(),
  link: yup
    .string()
    .url()
    .when("fileSource", {
      is: "link",
      then: yup.string().url().required("Link to file required"),
      otherwise: yup.string(),
    }),
  file: yup.mixed().when("fileSource", {
    is: "file",
    then: yup.mixed().required("File required"),
    otherwise: yup.mixed(),
  }),
  fileTypeId: yup.string().required("Category required"),
});

export const connectionId = (
  edges: PropsType["edges"],
  fileTypeId?: string,
) => {
  const cache: { [key: string]: string } = {};

  return reduce(
    edges,
    (acc, edge: EdgeType) => {
      acc[edge?.node?.id || ""] = edge?.fileLinks?.__id || "";

      return acc;
    },
    cache,
  )[fileTypeId || ""];
};

const ProjectForm = ({ edges, fileLinkable, onSubmit }: PropsType) => {
  const [uploaded, setUploaded] = useState<"none" | "inProgress" | "done">(
    "none",
  );
  const { toastError } = useToast();
  const onError = (err: Error) => {
    toastError({ message: err.message });
  };

  const [commit] = useMutation<CreateFileForm_Mutation>(mutation);

  return (
    <Formik
      initialValues={{
        name: "",
        link: "",
        fileTypeId: edges.length === 1 ? (edges[0]?.node?.id || '') : "",
        file: undefined,
        fileSource: "link",
      }}
      onSubmit={(data, { resetForm }) => {
        const { name, link, fileTypeId, file, fileSource } = data;
        const variables = {
          input: {
            fileLink: {
              fileLinkableId: fileLinkable.id,
              fileLinkableType: fileLinkable.type,
              fileTypeId,
              blobId: fileSource === "file" && file ? file : undefined,
              link: fileSource === "link" ? link : "",
              name: name || link,
            },
          },
          connections: [connectionId(edges, fileTypeId)],
        };
        commit({
          variables,
          onError,
          onCompleted: () => {
            onSubmit && onSubmit();
            resetForm();
          },
        });
      }}
      validationSchema={schema}
      render={({
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>File name (for your reference)</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            {edges.length > 1 && <Form.Group controlId="fileTypeId">
              <Form.Label>File category</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fileTypeId}
              >
                <option value={undefined}>---</option>
                {map(edges, (edge) => (
                  <option key={edge?.node?.id} value={edge?.node?.id}>
                    {edge?.node?.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>}

            <Form.Group className="ml-4 my-2">
              <Form.Check // prettier-ignore
                type="switch"
                id="fileSourceToggle"
                label="Link or File"
                checked={values.fileSource === "link"}
                onChange={() =>
                  setFieldValue(
                    "fileSource",
                    values.fileSource === "link" ? "file" : "link",
                  )
                }
              />
            </Form.Group>

            {values.fileSource === "link" ? (
              <Form.Group controlId="link">
                <Form.Label>Link</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.link}
                  type="link"
                  isValid={touched.link && !errors.link}
                  isInvalid={touched.link && !!errors.link}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.link}
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              <Form.Group controlId="file">
                <div>
                  <input
                    type="file"
                    name="file"
                    // set supported file types here,
                    // could also check again within formik validation or backend
                    // accept="image/png, .svg"
                    onChange={(e) => {
                      // Object is possibly null error w/o check
                      if (e.currentTarget.files) {
                        setUploaded("inProgress");
                        setFieldValue("file", null);
                        uploadFile({
                          file: e.currentTarget.files[0],
                          onCompleted: ({ signedBlobId, name }) => {
                            setFieldValue("file", signedBlobId);
                            if (values.name === "") {
                              setFieldValue("name", name);
                            }
                            setUploaded("done");
                          },
                        });
                      }
                    }}
                  />
                  {uploaded === "inProgress" && (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )}
                  {uploaded === "done" && (
                    <i className="bi bi-check-circle"></i>
                  )}
                </div>
              </Form.Group>
            )}

            <Form.Control.Feedback
              type="invalid"
              className={
                touched.fileTypeId && errors.fileTypeId ? "d-block" : ""
              }
            >
              {errors.fileTypeId}
            </Form.Control.Feedback>

            <Button
              className="mt-2"
              variant="primary"
              type="submit"
              disabled={isSubmitting || uploaded === "inProgress"}
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default ProjectForm;
