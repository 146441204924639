import { compact, map, slice } from "lodash";

import { graphql } from "babel-plugin-relay/macro";
import { Badge } from "react-bootstrap";
import { useFragment } from "react-relay";
import { useAssignment, type Assignables } from "@/pages_old/Assign/Assignment";
export { type Assignables } from "@/pages_old/Assign/Assignment";

import Avatar from "./Avatar";
import { Avatars_users$key as AvatarsType } from "./__generated__/Avatars_users.graphql";

export type { AvatarsType };

interface PropsType {
  users: AvatarsType;
  max?: number;
  onClick?: () => void;
  overlap?: boolean;
  iconOnEmpty?: boolean;
  assignable?: {
    id: string;
    type: Assignables;
  }
}

const fragment = graphql`
  fragment Avatars_users on UserConnection {
    ...Assignment_Assignees
    edges {
      node {
        id
        ...Avatar_user
      }
    }
  }
`;

const AddOne = ({ onClick }: { onClick: () => void }) => {
  return(
    <span
      onClick={onClick}
      className="avatar-inner avatar-inner-hover bg-white border-dashed border-primary"
    >
      <i className="mdi mdi-account-multiple-plus h4 my-0 text-primary" style={{ height: 'inherit' }}></i>
    </span>
  )
}

export default (props: PropsType) => {
  const users = useFragment<AvatarsType>(fragment, props.users);
  const { assignable, iconOnEmpty = false, max, onClick } = props;
  var edges = users?.edges || [];

  var leftOver = -1;

  var toggleShow = () => console.log('attempt');
  var AssignmentModal = () => (<></>);

  if(assignable) {
    const assignmentHelper = useAssignment({ assignable, assignees: users });
    toggleShow = assignmentHelper.toggleShow;
    AssignmentModal = assignmentHelper.Modal;
  }

  if (max) {
    edges = slice(edges, 0, max);
    leftOver = (users.edges || []).length - max;
  }

  if (edges.length === 0 && iconOnEmpty) {
    return <i onClick={onClick} className="bi bi-person-add"></i>;
  }

  return (
    <>
      <AssignmentModal />
      <div className="mb-0 d-flex ms-2" onClick={toggleShow}>
        {map(compact(edges), ({ node: user }) => {
          if (!user) return <></>;
          return (
            <Avatar
              key={user.id}
              user={user}
              onClick={onClick}
              showName={edges.length < 2}
            />
          );
        })}
        <span>
          {leftOver > 0 ? (
            <Badge bg="danger" className="ml--2">
              +{leftOver}
            </Badge>
          ) : (
            <></>
          )}
        </span>
      </div>
      {assignable && compact(edges).length < 1 && <AddOne onClick={toggleShow} />}
    </>
  );
};
