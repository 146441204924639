import { type SessionType } from "../reducers/SessionReducer";
export type LocalStoreType = {
  sessionState?: {
    session: SessionType;
  },
  display?: {
    mode: 'light' | 'dark',
    orientation: 'vertical' | 'horizontal'
  };
};

export function loadStore(): LocalStoreType {
  try {
    const serializedStore = localStorage.getItem("store");
    if (serializedStore === null) {
      return {};
    }
    return JSON.parse(serializedStore);
  } catch (err) {
    return {};
  }
}

export const saveStore = (state: any) => {
  try {
    const serializedStore = JSON.stringify(state);
    localStorage.setItem("store", serializedStore);
  } catch (err) {
    console.log("unable to save state", state);
  }
};
