import { Formik } from "formik";
import { map } from "lodash";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

import {
  PositionForm_Mutation,
  type PositionLevel,
} from "./__generated__/PositionForm_Mutation.graphql";

const mutation = graphql`
  mutation PositionForm_Mutation(
    $input: PositionCreateInput!
    $connections: [ID!]!
  ) {
    createPosition(input: $input) {
      position
        @appendNode(connections: $connections, edgeTypeName: "PositionEdge") {
        ...PositionsPage_position
      }
    }
  }
`;

type InputType = {
  name: string;
  description: string;
  level: PositionLevel | undefined;
  clubId: string;
};

const createPosition = (position: InputType, connectionId: string) => {
  const variables = {
    input: {
      position,
    },
    connections: [connectionId],
  };
  commitMutation<PositionForm_Mutation>(environment, {
    mutation,
    variables,
  });
};

const schema = yup.object().shape({
  name: yup.string().required("Name required"),
  description: yup.string(),
  level: yup.string(),
});

const levels = ["executive", "committee", "subcommittee", "officer", "casual"];

const PositionForm = ({
  connectionId,
  onSubmit,
}: {
  connectionId: string;
  onSubmit: () => void;
}) => {
  const { currentClub } = getCurrentClub();
  const clubId = currentClub?.id;

  return (
    <Formik
      initialValues={{ name: "", description: "", level: undefined }}
      onSubmit={({ name, description, level }, actions) => {
        createPosition({ clubId, level, name, description }, connectionId);
        onSubmit();
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                type="name"
                placeholder="Name"
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>

            <Form.Group controlId="description">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                type="description"
                as="textarea"
                rows={5}
                placeholder="Description"
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
              />
              <Form.Control.Feedback type="invalid">
                {errors.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="level">
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.level}
              >
                <option value={undefined}>---</option>
                {map(levels, (level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default PositionForm;
