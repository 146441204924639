import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";

import {
  TaskUpdateInputObject,
  UpdateTask_Mutation,
} from "./__generated__/UpdateTask_Mutation.graphql";

const mutation = graphql`
  mutation UpdateTask_Mutation($input: TaskUpdateInput!) {
    updateTask(input: $input) {
      task {
        id
        description
        name
        dueDate
        complete
        assignees {
          ...Assignment_Assignees
          ...Avatars_users
        }
      }
    }
  }
`;

type ConfigType =
  | {
      onCompleted?: () => void;
    }
  | undefined
  | null;

export const updateTask = (
  task: TaskUpdateInputObject,
  config?: ConfigType,
) => {
  const variables = {
    input: {
      task,
    },
  };

  const { onCompleted } = config || {};

  commitMutation<UpdateTask_Mutation>(environment, {
    mutation,
    variables,
    onCompleted,
  });
};

export default updateTask;
