import { useField } from "formik";
import React from "react";

import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
export { type EditorState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface PropsType {
  name: string;
  readOnly?: boolean;
  onChange?: (v: EditorState) => void;
}

const EditorToolbar = {
  options: [
    "inline",
    // "blockType",
    // "fontSize",
    // "list",
    // "textAlign",
    // "colorPicker",
    // "link",
    // "remove",
    // "history",
  ],
  fontSize: {
    className: "lato",
  },
  blockType: {
    className: "lato",
  },
  colorPicker: {
    popupClassName: "lato",
  },
};

export const stringToState = (str: string): EditorState => {
  var initialDescriptionJSON = null;

  try {
    initialDescriptionJSON = JSON.parse(str);
  } catch {
    initialDescriptionJSON = convertToRaw(ContentState.createFromText(str));
  }

  const initialDescription: EditorState = EditorState.createWithContent(
    convertFromRaw(initialDescriptionJSON),
  );

  return initialDescription;
};

const stateToString = (state: EditorState): string => {
  return JSON.stringify(convertToRaw(state.getCurrentContent()));
};

const RichEditor: React.FC<PropsType> = (props) => {
  const { readOnly = false, onChange = () => undefined } = props;
  const [field, , helpers] = useField<EditorState>(props.name);

  return (
    <Editor
      readOnly={readOnly}
      toolbar={EditorToolbar}
      wrapperStyle={{
        border: "1px solid #d6d6d6",
        padding: 10,
        borderRadius: 10,
      }}
      toolbarStyle={{
        border: 0,
        borderBottom: "1px solid #d6d6d6",
      }}
      editorState={field.value}
      onEditorStateChange={(v) => {
        helpers.setValue(v);
        onChange(v);
      }}
      onBlur={() => helpers.setTouched(true)}
    />
  );
};

const useRichEditor = () => {
  return { stateToString, stringToState, RichEditor };
};

export default useRichEditor;
