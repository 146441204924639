import { graphql } from "babel-plugin-relay/macro";
import Moment from "moment";
import { Suspense, useState } from "react";
import { useFragment } from "react-relay";

import Avatar from "@/pages_old/Users/Avatar";
import { compact, map } from "lodash";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import createComment, {
  type Commentables,
} from "@/pages_old/Feed/CreateComment";
export { type Commentables } from "@/pages_old/Feed/CreateComment";

import {
  type CommentFeed_comments$data as CommentConnectionType,
  type CommentFeed_comments$key as ConnectionTypeKey,
} from "./__generated__/CommentFeed_comments.graphql";

export { type CommentFeed_comments$key as ConnectionTypeKey } from "./__generated__/CommentFeed_comments.graphql";

export type CommentableType = {
  id: string;
  type: Commentables;
};

interface PropsType {
  className?: string;
  commentable: CommentableType;
}

export type CommentPropsType = PropsType & {
  comments?: ConnectionTypeKey;
};

type CommentType = Exclude<
  Exclude<
    Exclude<CommentConnectionType["comments"], null | undefined>["edges"],
    null | undefined
  >[number],
  null | undefined
>["node"];

type ReplyType = Exclude<
  Exclude<
    Exclude<
      Exclude<CommentType, null | undefined>["comments"],
      null | undefined
    >["edges"],
    null | undefined
  >[number],
  null | undefined
>["node"];

const commentsQuery = graphql`
  fragment CommentFeed_comments on CommentableInterface {
    comments(order: [{ key: createdAt, value: desc }]) {
      totalCount
      __id
      edges {
        node {
          id
          content
          author {
            name
            ...Avatar_user
          }
          likes {
            totalCount
          }
          createdAt
          comments(order: [{ key: createdAt, value: desc }]) {
            __id
            edges {
              node {
                createdAt
                id
                content
                author {
                  name
                  ...Avatar_user
                }
                likes {
                  totalCount
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Comment = ({
  currentReplyId,
  comment,
  className,
  children,
  onSetReply,
}: {
  onSetReply?: () => void;
  comment?: ReplyType;
  className?: string;
  children?: React.ReactNode;
  currentReplyId?: string | null;
}) => {
  const { author, content, createdAt } = comment || {};
  if (!comment) {
    return <></>;
  }

  return (
    <div className={`d-flex ${className}`}>
      {author && <Avatar user={author} className="ms-0" />}
      <div className="w-100">
        {author && (
          <h5 className="mt-0">
            {author.name}
            <small className="text-muted float-end">
              {Moment(createdAt).fromNow()}
            </small>
          </h5>
        )}
        {content}
        <br />
        {onSetReply && (
          <a
            onClick={onSetReply}
            className={`font-13 d-inline-block mt-2 ${currentReplyId === comment.id ? "text-primary" : "text-muted"}`}
          >
            <i className={`mdi mdi-reply `}></i>{" "}
            {currentReplyId === comment.id ? "Replying" : "Reply"}
          </a>
        )}
        {children}
      </div>
    </div>
  );
};

const TopComment = ({
  comment,
  onSetReply,
  currentReplyId,
}: {
  comment?: CommentType;
  onSetReply: () => void;
  currentReplyId?: string | null;
}) => {
  return (
    <Comment
      className="RootComment mt-3"
      comment={comment}
      onSetReply={onSetReply}
      currentReplyId={currentReplyId}
    >
      <>
        {map(
          compact((comment?.comments || { edges: [] })?.edges || []),
          ({ node: reply }) => (
            <Comment
              comment={reply}
              className="mt-3"
              currentReplyId={currentReplyId}
              key={reply?.id}
            />
          ),
        )}
      </>
    </Comment>
  );
};

const Comments = ({
  commentable,
  comments: commentKey,
  className,
}: CommentPropsType) => {
  // TODO: have as secondary query on page load;
  const { comments } =
    useFragment<ConnectionTypeKey>(commentsQuery, commentKey) || {};
  if (!comments) return <></>;

  const { __id } = comments;

  const { totalCount, edges = [] } = comments;
  const [repliable, setRepliable] = useState<CommentType | null>();
  const [commentState, setCommentState] = useState("");

  const handleSubmit = () => {
    createComment(
      {
        connectionId: repliable ? repliable?.comments?.__id || "" : __id,
        commentableId: repliable ? repliable.id : commentable.id,
        commentableType: repliable ? "comment" : commentable.type,
        content: commentState,
      },
      {
        resetForm: () => setCommentState(""),
      },
    );
  };

  return (
    <Card>
      <Card.Header>
        <h4 className="my-1">Comments ({totalCount})</h4>
      </Card.Header>

      <Card.Body>
        {map(compact(edges), (edge) => {
          return (
            <TopComment
              key={edge.node?.id || ""}
              comment={edge.node}
              onSetReply={() => {
                setRepliable(edge.node);
              }}
              currentReplyId={repliable?.id}
            />
          );
        })}

        <div className="mt-4">
          {repliable && (
            <span className="ms-1 px-1 rounded-top bg-secondary bg-opacity-10 ">
              Replying to {repliable.author.name}
              <i
                className="uil uil-times"
                onClick={() => setRepliable(null)}
              ></i>
            </span>
          )}
        </div>
        <div className="border rounded">
          <div className="comment-area-box">
            <textarea
              value={commentState}
              onChange={(changeEvent) =>
                setCommentState(changeEvent.target.value)
              }
              rows={3}
              className="form-control border-0 resize-none"
              placeholder="Your comment..."
            />
            <div className="p-2 bg-light d-flex justify-content-between align-items-center">
              <button className="btn btn-sm btn-primary" onClick={handleSubmit}>
                <i className="uil uil-message me-1"></i>Submit
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const Loading = () => (
  <div className="text-center mt-2">
    <Link to="" className="text-danger">
      <i className="mdi mdi-spin mdi-loading me-1"></i> Load more
    </Link>
  </div>
);

const CommentsLoader = (props: CommentPropsType) => {
  return (
    <Suspense fallback={<Loading />}>
      <Comments {...props} />
    </Suspense>
  );
};

export default CommentsLoader;
