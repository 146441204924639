import Moment from "moment";

import { graphql } from "babel-plugin-relay/macro";
import LinesEllipsis from "react-lines-ellipsis";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { permissions } from "../../services/permissions";

import { compact, map } from "lodash";
import { Button, Container, Table } from "react-bootstrap";

import {
  Tasks_TaskConnection$data as TaskConnectionDataType,
  Tasks_TaskConnection$key as TaskConnectionType,
} from "./__generated__/Tasks_TaskConnection.graphql";

import Assign from "../Assign";
import Avatars from "../Users/Avatars";
import { type Taskables } from "./CreateTaskForm";
import CreateTaskModal from "./CreateTaskModal";
import removeTask from "./RemoveTask";
import UpdateModal from "./UpdateModal";

import typeToRoute from "../../services/typeToRoute";
import {
  type OrderKeys,
  type OrderType,
} from "../Pages/__generated__/TasksPageQuery.graphql";

interface PropsType {
  order?: OrderType[];
  toggleOrder?: (key: OrderKeys) => void;
  taskable: {
    id: string;
    type: Taskables;
  };
  tasks: TaskConnectionType;
  showParent?: boolean;
}

type TaskEdgeType = Exclude<
  TaskConnectionDataType["edges"],
  null | undefined
>[number];

type ParentType = Exclude<
  Exclude<TaskEdgeType, null | undefined>["node"],
  null | undefined
>["parent"];

graphql`
  fragment Tasks_TaskNode on Task {
    id
    assignees {
      edges {
        node {
          id
        }
      }
      ...Avatars_users
      ...Assignment_Assignees
    }
    dueDate
    complete
    description
    parent {
      ... on TaskableInterface {
        id
        name
        __typename
      }
    }
    comments {
      totalCount
    }
  }
`;

const fragment = graphql`
  fragment Tasks_TaskConnection on TaskConnection {
    __id
    edges {
      node {
        ...Tasks_TaskNode @relay(mask: false)
      }
    }
  }
`;

export const ParentLink = ({
  className,
  owner,
}: {
  className?: string;
  owner: ParentType;
}) => {
  if (typeToRoute(owner?.__typename || "")) {
    return (
      <Link
        className={className}
        to={(typeToRoute(owner?.__typename || "") || "") + owner.id}
      >
        <LinesEllipsis maxLine="1" text={owner.name || ""} />
      </Link>
    );
  }
  return <></>;
};

const EdgeRow = ({
  showParent,
  edge,
  connectionId,
}: {
  showParent: boolean;
  connectionId: string;
  edge: TaskEdgeType;
}) => {
  if (!edge) return <></>;
  const { node } = edge;
  if (!node) return <></>;
  const {
    id,
    description,
    dueDate,
    complete,
    assignees,
    parent: owner,
    comments,
  } = node;
  const { totalCount } = comments || { totalCount: 0 };

  const {
    task: { update: taskUpdate, delete: taskDelete },
    assignment: { create: assignmentCreate },
  } = permissions();

  const avatar = (
    <Assign assignable={{ id, type: "task" }} assignees={assignees}>
      {(toggleShow) => {
        if (assignees && (assignees.edges || []).length > 0) {
          return (
            <Avatars
              onClick={assignmentCreate ? toggleShow : () => {}}
              users={assignees}
            />
          );
        } else {
          return assignmentCreate ? (
            <Button onClick={toggleShow}>+</Button>
          ) : (
            <></>
          );
        }
      }}
    </Assign>
  );

  return (
    <tr id={id} key={id}>
      <td>
        <Link to={`/tasks/${id}`}>
          {description}
          {totalCount > 0 ? <i className="bi bi-chat"></i> : <></>}
        </Link>
      </td>
      <td className="d-flex">{avatar}</td>
      <td>{dueDate && Moment(dueDate).format("D MMM")}</td>
      <td>
        <i className={complete ? "bi bi-check" : "bi bi-x"}></i> {}
      </td>
      {showParent ? (
        <td>
          <ParentLink owner={owner} />
        </td>
      ) : (
        <></>
      )}
      {node && taskUpdate ? (
        <td className="text-center">
          <UpdateModal task={node}>
            {({ onClick }) => (
              <i onClick={onClick} className="bi bi-pencil"></i>
            )}
          </UpdateModal>
        </td>
      ) : (
        <td></td>
      )}
      {taskDelete ? (
        <td
          className="text-center"
          onClick={() => removeTask(id, connectionId)}
        >
          <i className="bi bi-x danger" style={{ color: "red" }}></i>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

const Header = ({
  toggleOrder,
  name,
  order,
  label,
}: {
  toggleOrder?: ((name: OrderKeys) => void) | undefined;
  name?: OrderKeys;
  label: string;
  order: OrderType | undefined;
}) => {
  return (
    <th onClick={() => name && toggleOrder && toggleOrder(name)}>
      {label}
      <IconForOrder name={name} order={order} />
    </th>
  );
};

const IconForOrder = ({
  name,
  order,
}: {
  name: OrderKeys | undefined;
  order: OrderType | undefined;
}) => {
  if (name && order?.key === name) {
    if (order?.value === "asc") {
      return <i className="bi bi-caret-down"></i>;
    } else {
      return <i className="bi bi-caret-up"></i>;
    }
  }
  return <></>;
};

const Tasks = (props: PropsType) => {
  const tasks = useFragment<TaskConnectionType>(fragment, props.tasks);

  const { showParent, taskable, order: orderList, toggleOrder } = props;

  const order = orderList && orderList[0];

  const {
    task: { create: taskCreate },
  } = permissions();

  return (
    <Container className="px-0">
      {taskCreate && (
        <CreateTaskModal connectionId={tasks.__id} taskable={taskable} />
      )}
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <Header {...{ order, label: "Action" }} />
              <Header {...{ order, label: "Assignees" }} />
              <Header {...{ toggleOrder, order, name: "dueDate", label: "Due" }} />
              <Header {...{ order, label: "Complete" }} />
              {showParent ? <th>Parent</th> : <></>}
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {map(compact(tasks?.edges || []), (edge) => (
              <EdgeRow
                key={edge.node?.id}
                edge={edge}
                connectionId={tasks.__id}
                showParent={!!showParent}
              />
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default Tasks;
