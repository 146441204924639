/**
 * @generated SignedSource<<1d1453e951512153c8379797f15b8771>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MembershipRoleKindEnumType = "admin" | "coordinator" | "general" | "%future added value";
export type SignInRefreshUserMutation$variables = Record<PropertyKey, never>;
export type SignInRefreshUserMutation$data = {
  readonly queryRefresh: {
    readonly viewer: {
      readonly user: {
        readonly clubs: {
          readonly edges: ReadonlyArray<{
            readonly id: string;
            readonly kind: MembershipRoleKindEnumType | null | undefined;
            readonly node: {
              readonly id: string;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        };
      } | null | undefined;
    };
  };
};
export type SignInRefreshUserMutation = {
  response: SignInRefreshUserMutation$data;
  variables: SignInRefreshUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "input",
    "value": {}
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "count",
      "value": 1
    }
  ],
  "concreteType": "UserClubConnection",
  "kind": "LinkedField",
  "name": "clubs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserClubEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Club",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "clubs(count:1)"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInRefreshUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "RefreshUserPayload",
        "kind": "LinkedField",
        "name": "queryRefresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": "user",
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "queryRefresh(input:{})"
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignInRefreshUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "RefreshUserPayload",
        "kind": "LinkedField",
        "name": "queryRefresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": "user",
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "queryRefresh(input:{})"
      }
    ]
  },
  "params": {
    "cacheID": "0dcafb73e5f20906536dc07acbe0547c",
    "id": null,
    "metadata": {},
    "name": "SignInRefreshUserMutation",
    "operationKind": "mutation",
    "text": "mutation SignInRefreshUserMutation {\n  queryRefresh(input: {}) {\n    viewer {\n      user: currentUser {\n        clubs(count: 1) {\n          edges {\n            kind\n            id\n            node {\n              id\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aea9d4b4597c5b562f30dbe73acf0a05";

export default node;
