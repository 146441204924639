const { Environment, Network, RecordSource, Store } = require("relay-runtime");

const { graphqlEndpoint } = require("./services/endpoint");
const { loadStore } = require("./redux/store/localStorage");

const store = new Store(new RecordSource());

const authToken = () => {
  try {
    return loadStore().sessionState.session.access.token;
  } catch (err) {
    return null;
  }
};

const headers = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: authToken() ? `Bearer ${authToken()}` : "",
});

const fetchQuery = (operation: any, variables: any) => {
  return fetch(graphqlEndpoint(), {
    method: "POST",
    headers: headers(),
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  })
    .then((response) => {
      const json = response.json();
      return json.then((r) => {
        return r;
      });
    })
    .catch((e: Error) => {
      console.log("er", e);
      return new Promise((e) => {
        return {};
      });
    });
};

const network = Network.create(fetchQuery);

const environment = new Environment({
  network,
  store,
});

export default environment;
