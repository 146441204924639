import {
  DndContext,
  pointerWithin,
  useSensor,
  type DragEndEvent,
} from "@dnd-kit/core";
import { compact, groupBy as lodashGroupBy, map } from "lodash";
import { Table as BSTable } from "react-bootstrap";
import Droppable from "./Droppable";

import {
  PointerSensor as LibPointerSensor,
  TouchSensor as LibTouchSensor,
} from "@dnd-kit/core";
import { MouseEvent, TouchEvent } from "react";

interface PropsType<T> {
  columns: ReadonlyArray<string | null | undefined>;
  data: ReadonlyArray<T>;
  groupBy: string;
  children: (v: T) => JSX.Element;
  handleDragEnd: (event: DragEndEvent) => void;
  HeaderRenderer?: (rendererProps: {
    column: string | null | undefined;
    data: ReadonlyArray<T>;
  }) => JSX.Element;
  className?: string;
}

function DefaultHeaderRenderer<T>({
  column,
  data,
}: {
  column: string | null | undefined;
  data: ReadonlyArray<T>;
}) {
  return <th key={column}>{column}</th>;
}

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement;

  while (cur) {
    if (
      cur.dataset &&
      (cur.dataset.noDnd || cur.dataset.rrUiModalOpen === "")
    ) {
      return false;
    }
    cur = cur.parentElement as HTMLElement;
  }

  return true;
};

export class PointerSensor extends LibPointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler,
    },
  ] as (typeof LibPointerSensor)["activators"];
}
export class TouchSensor extends LibTouchSensor {
  static activators = [
    {
      eventName: "onTouchStart",
      handler,
    },
  ] as (typeof LibTouchSensor)["activators"];
}

function Table<DataType>({
  className = "",
  columns,
  data,
  groupBy,
  children,
  handleDragEnd,
  HeaderRenderer = DefaultHeaderRenderer,
}: PropsType<DataType>) {
  const groupedData = lodashGroupBy(compact(data), groupBy);

  const pointer = useSensor(PointerSensor);
  const toucher = useSensor(TouchSensor);

  return (
    <DndContext
      collisionDetection={pointerWithin}
      sensors={[toucher, pointer]}
      onDragEnd={handleDragEnd}
    >
      <div className="col-12 table-responsive">
        <BSTable>
          <thead>
            <tr>
              {columns.map((column) => (
                <HeaderRenderer
                  key={column}
                  column={column}
                  data={groupedData[String(column)] || []}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {map(columns, (col: string | null | undefined) => (
                <Droppable id={col} key={col} className={className}>
                  <div>
                    {map(compact(groupedData[String(col)] || []), (p) =>
                      children(p),
                    )}
                  </div>
                </Droppable>
              ))}
            </tr>
          </tbody>
        </BSTable>
      </div>
    </DndContext>
  );
}

export default Table;
