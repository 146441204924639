/*  Imports from Redux:
 applyMiddleware: Applies middleware to the dispatch method of the Redux store
 combineReducers: Merges reducers into one
 createStore: Creates a Redux store that holds the state tree
 Store: The TS Type used for the store, or state tree
 */
import { useDispatch } from "react-redux";
import { applyMiddleware, combineReducers, createStore, Store } from "redux";
/*  Thunk
Redux Thunk middleware allows you to write action creators that return a function instead of an action. The thunk can be used to delay the dispatch of an action, or to dispatch only if a certain condition is met. The inner function receives the store methods dispatch and getState as parameters.
*/
import thunk from "redux-thunk";
// Import reducers and state type
import { sessionReducer, SessionStateType } from "../reducers/SessionReducer";

import {
  currentClubReducer,
  CurrentClubStateType,
} from "../reducers/CurrentClubReducer";

// Create an interface for the application state
export interface AppStateType {
  sessionState: SessionStateType;
  currentClub: CurrentClubStateType;
}

// Create the root reducer
const rootReducer = combineReducers<AppStateType>({
  sessionState: sessionReducer,
  currentClub: currentClubReducer,
});

// Create a configure store function of type `IAppState`
export default function configureStore({ defaultStore = {} } = {}): Store<
  AppStateType,
  any
> {
  const store = createStore(rootReducer, defaultStore, applyMiddleware(thunk));
  return store;
}

const store = createStore(rootReducer, {}, applyMiddleware(thunk));

export type AppStore = typeof store;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
