/**
 * @generated SignedSource<<d955b95bf2e01c7d6e78eb512db72660>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Checklist_TaskConnection$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly comments: {
        readonly totalCount: number;
      } | null | undefined;
      readonly complete: boolean;
      readonly dueDate: any | null | undefined;
      readonly id: string;
      readonly parent: {
        readonly __typename?: string;
        readonly id?: string;
        readonly name?: string | null | undefined;
      };
      readonly title: string;
      readonly users: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
          } | null | undefined;
        } | null | undefined> | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "Checklist_TaskConnection";
};
export type Checklist_TaskConnection$key = {
  readonly " $data"?: Checklist_TaskConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"Checklist_TaskConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Checklist_TaskConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": "users",
              "args": null,
              "concreteType": "UserConnection",
              "kind": "LinkedField",
              "name": "assignees",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Avatars_users"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "Assignment_Assignees"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dueDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "complete",
              "storageKey": null
            },
            {
              "alias": "title",
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "parent",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "type": "TaskableInterface",
                  "abstractKey": "__isTaskableInterface"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CommentConnection",
              "kind": "LinkedField",
              "name": "comments",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalCount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "TaskConnection",
  "abstractKey": null
};
})();

(node as any).hash = "85cc5a76dc76ac0023548c314250fe11";

export default node;
