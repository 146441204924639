import { useDraggable } from "@dnd-kit/core";

interface PropsType {
  id: string | null | undefined;
  children: JSX.Element;
  disabled: boolean;
  className?: string;
}

function Draggable({ className, id, children, disabled }: PropsType) {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: String(id),
      disabled,
    });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    rotate: isDragging ? "2deg" : undefined,
    boxShadow: isDragging ? "10px 5px 5px #d1d1e0" : undefined,
  };

  return (
    <button
      className={className}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
    >
      {children}
    </button>
  );
}

export default Draggable;
