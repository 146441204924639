/**
 * @generated SignedSource<<3cda98cc555cbd07216684ecc7ae64c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectMonth = "april" | "august" | "december" | "february" | "january" | "july" | "june" | "march" | "may" | "november" | "october" | "september" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ShowPageProject_project$data = {
  readonly assignees: {
    readonly " $fragmentSpreads": FragmentRefs<"Assignment_Assignees" | "Avatars_users">;
  } | null | undefined;
  readonly completed: boolean;
  readonly description: string | null | undefined;
  readonly fileTypes: {
    readonly " $fragmentSpreads": FragmentRefs<"Attachments_FileType">;
  } | null | undefined;
  readonly id: string;
  readonly link: string | null | undefined;
  readonly month: ProjectMonth | null | undefined;
  readonly name: string | null | undefined;
  readonly tasks: {
    readonly __id: string;
    readonly totalCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"Checklist_TaskConnection">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CommentFeed_comments">;
  readonly " $fragmentType": "ShowPageProject_project";
};
export type ShowPageProject_project$key = {
  readonly " $data"?: ShowPageProject_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShowPageProject_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowPageProject_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserConnection",
      "kind": "LinkedField",
      "name": "assignees",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Avatars_users"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Assignment_Assignees"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "link",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentFeed_comments"
        }
      ],
      "type": "CommentableInterface",
      "abstractKey": "__isCommentableInterface"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SuggestedFileTypeConnection",
      "kind": "LinkedField",
      "name": "fileTypes",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Attachments_FileType"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            {
              "key": "dueDate",
              "value": "asc"
            }
          ]
        }
      ],
      "concreteType": "TaskConnection",
      "kind": "LinkedField",
      "name": "tasks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Checklist_TaskConnection"
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "tasks(order:[{\"key\":\"dueDate\",\"value\":\"asc\"}])"
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "d8e0afb93f3f93e2187aec5b6050e67e";

export default node;
