/**
 * @generated SignedSource<<0c39cf8fc77e035384ca496bda0ed475>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Assignables = "meeting" | "position" | "project" | "task" | "%future added value";
export type UnAssignMutation$variables = {
  assignableId: string;
  assignableType: Assignables;
  assigneeId?: string | null | undefined;
  connections: ReadonlyArray<string>;
};
export type UnAssignMutation$data = {
  readonly assignDelete: {
    readonly deletedAssignmentIds: ReadonlyArray<string>;
  };
};
export type UnAssignMutation = {
  response: UnAssignMutation$data;
  variables: UnAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignableId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignableType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assigneeId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v4 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assignableId",
            "variableName": "assignableId"
          },
          {
            "kind": "Variable",
            "name": "assignableType",
            "variableName": "assignableType"
          },
          {
            "kind": "Variable",
            "name": "assigneeId",
            "variableName": "assigneeId"
          }
        ],
        "kind": "ObjectValue",
        "name": "assign"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAssignmentIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AssignDeletePayload",
        "kind": "LinkedField",
        "name": "assignDelete",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "AssignDeletePayload",
        "kind": "LinkedField",
        "name": "assignDelete",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteEdge",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedAssignmentIds",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "375b6df4bf83e986146fdaebf0ef8737",
    "id": null,
    "metadata": {},
    "name": "UnAssignMutation",
    "operationKind": "mutation",
    "text": "mutation UnAssignMutation(\n  $assigneeId: ID\n  $assignableId: ID!\n  $assignableType: Assignables!\n) {\n  assignDelete(input: {assign: {assigneeId: $assigneeId, assignableId: $assignableId, assignableType: $assignableType}}) {\n    deletedAssignmentIds\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7fed574827fc4cd637bce0e7d98c311";

export default node;
