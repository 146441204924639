// Import Reducer type
import { Reducer } from "redux";
import { SessionActions, SessionActionTypes } from "../actions/SessionActions";

// Define the Session type
export interface SessionType {
  refresh: {
    token: string | null;
  };
  access: {
    token: string | null;
    expiresAt: string | null;
  };
}

// Define the Session State
export interface SessionStateType {
  readonly session: SessionType;
}

// Define the initial state
const initialSessionState: SessionStateType = {
  session: {
    refresh: {
      token: null,
    },
    access: {
      token: null,
      expiresAt: null,
    },
  },
};

export const sessionReducer: Reducer<SessionStateType, SessionActions> = (
  state = initialSessionState,
  action,
) => {
  switch (action.type) {
    case SessionActionTypes.LOGIN: {
      return {
        ...state,
        session: action.session,
      };
    }
    case SessionActionTypes.LOGOUT: {
      return {
        ...initialSessionState,
      };
    }
    default:
      return state;
  }
};
