import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  UnAssignMutation$variables as InputType,
  UnAssignMutation,
} from "./__generated__/UnAssignMutation.graphql";

interface ConfigType {
  onCompleted?: () => void;
}

const mutation = graphql`
  mutation UnAssignMutation(
    $assigneeId: ID
    $assignableId: ID!
    $assignableType: Assignables!
    $connections: [ID!]!
  ) {
    assignDelete(
      input: {
        assign: {
          assigneeId: $assigneeId
          assignableId: $assignableId
          assignableType: $assignableType
        }
      }
    ) {
      deletedAssignmentIds @deleteEdge(connections: $connections)
    }
  }
`;

export default (props: InputType, config?: ConfigType | undefined) => {
  commitMutation<UnAssignMutation>(environment, {
    mutation,
    variables: props,
    onCompleted: config?.onCompleted,
    updater: (store) => {
      const assignable = store.get(props.assignableId);
      const assignees = assignable?.getLinkedRecord("assignees");
      const currentCount = assignees?.getValue("totalCount");
      assignees?.setValue(Number(currentCount || 0) - 1, "totalCount");
    },
  });
};
