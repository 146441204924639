import { Formik } from "formik";
import { map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import updateMembership from "./UpdateMembership";

import { type MembershipRoleKindEnumType } from "./__generated__/UpdateMembership_Mutation.graphql";
export { type MembershipRoleKindEnumType } from "./__generated__/UpdateMembership_Mutation.graphql";

type PropsType = {
  id: string;
  onSubmit: () => void;
  role?: MembershipRoleKindEnumType | undefined | null;
};

const schema = yup.object().shape({
  role: yup.string(),
});

const roles = ["admin", "coordinator", "general"];

const InviteForm = ({ role, id, onSubmit }: PropsType) => {
  const [serverError, onServerError] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ role: role || undefined }}
      onSubmit={({ role: newRole }, actions) => {
        updateMembership({
          role: newRole,
          id,
          onError: (e) => {
            onServerError(e.message);
          },
          onCompleted: onSubmit,
        });
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="role">
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
              >
                <option value={undefined}>---</option>
                {map(roles, (role) => (
                  <option key={role} value={role}>
                    {t(`roles.${role}`)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {serverError}
            </Form.Control.Feedback>

            <Button
              variant="primary"
              type="submit"
              className="mt-2"
              disabled={isSubmitting}
            >
              Submit
            </Button>
            <div style={{ paddingTop: "1rem" }}>
              {!!status && <Alert variant="danger">{status}</Alert>}
            </div>
          </Form>
        );
      }}
    />
  );
};

export default InviteForm;
