import { Component } from "react";

import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";

interface PropsType {}

class MainPage extends Component<PropsType> {
  render() {
    return (
      <Container className="text-center p-3">
        Oops.
        <br />
        I think you're lost.
        <br />
        click <Link to="/">here</Link> to go home
      </Container>
    );
  }
}

export default MainPage;
