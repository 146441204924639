import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  CreateCommentMutation,
  type Commentables,
} from "./__generated__/CreateCommentMutation.graphql";
export { type Commentables } from "./__generated__/CreateCommentMutation.graphql";

const mutation = graphql`
  mutation CreateCommentMutation($input: CommentInput!, $connections: [ID!]!) {
    createComment(input: $input) {
      comment
        @prependNode(connections: $connections, edgeTypeName: "CommentEdge") {
        id
        content
        author {
          name
          ...Avatar_user
        }
        likes {
          totalCount
        }
        createdAt
        comments(order: [{ key: createdAt, value: desc }]) {
          __id
          edges {
            node {
              createdAt
              id
              content
              author {
                name
                ...Avatar_user
              }
              likes {
                totalCount
              }
            }
          }
        }
      }
    }
  }
`;

type InputType = {
  connectionId: string;
  commentableId: string;
  commentableType: Commentables;
  content: string;
};

type ActionsType = {
  resetForm: () => void;
};

export default (
  { connectionId, commentableId, commentableType, content }: InputType,
  { resetForm: onCompleted }: ActionsType,
) => {
  const variables = {
    connections: [connectionId],
    input: {
      comment: {
        commentableId,
        commentableType,
        content,
      },
    },
  };

  console.log(connectionId);
  commitMutation<CreateCommentMutation>(environment, {
    mutation,
    variables,
    // updater: updater(connectionId),
    onCompleted: () => onCompleted(),
  });
};
