import React, { useState } from "react";
import { Modal as BSModal, Tab } from "react-bootstrap";
import { permissions } from "../../services/permissions";
import UpdateForm, { type MembershipRoleKindEnumType } from "./UpdateForm";

const modalBodyStyle = {
  minHeight: "22rem",
};

type ChildrenType = ({ onClick }: { onClick: () => void }) => React.ReactNode;

const UpdateModal = ({
  id,
  role,
  children,
}: {
  id: string;
  role: MembershipRoleKindEnumType | undefined | null;
  children: ChildrenType;
}) => {
  const {
    membership: { create: membershipCreate },
  } = permissions();

  if (!membershipCreate) {
    return <></>;
  }
  const { Modal } = useUpdateUserModal({ id, role });

  return <Modal>{children}</Modal>;
};

export const useUpdateUserModal = (p: {
  id: string;
  role?: MembershipRoleKindEnumType | null;
}) => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(p.id);
  const [role, setRole] = useState(p.role);
  const onClick = () => setShow(!show);

  const Modal = ({ children }: { children?: ChildrenType }) => (
    <>
      {children && children({ onClick })}
      <BSModal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Tab.Container defaultActiveKey="signIn">
          <BSModal.Header closeButton>Edit Membership</BSModal.Header>
          <BSModal.Body style={modalBodyStyle}>
            <UpdateForm id={id} onSubmit={() => setShow(false)} role={role} />
          </BSModal.Body>
        </Tab.Container>
      </BSModal>
    </>
  );

  return {
    onClick,
    setId,
    setRole,
    Modal,
  };
};

export default UpdateModal;
