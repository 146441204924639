import React, { useState, useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TaskForm, { type Taskables } from "./CreateTaskForm";
export { type Taskables } from "./CreateTaskForm";

interface PropsType {
  connectionId: string;
  className?: string;
  taskable: {
    id: string;
    type: Taskables;
  };
}

const modalBodyStyle = {
  minHeight: "22rem",
};

const CreateTaskModal = ({
  children,
  connectionId,
  taskable,
  show,
  toggleShow,
}: PropsType & {
  show: boolean;
  toggleShow: () => void;
  children?: React.ReactNode;
}) => (
  <>
    {children}
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={toggleShow}
    >
      <Modal.Header closeButton>Create Task</Modal.Header>
      <Modal.Body style={modalBodyStyle}>
        <TaskForm
          connectionId={connectionId}
          taskable={taskable}
          onSubmit={toggleShow}
        />
      </Modal.Body>
    </Modal>
  </>
);

export const useCreateTask = (props: PropsType) => {
  const [show, updateShow] = useState(false);
  const toggleShow = () => {
    updateShow(!show);
  }

  const Modal = ({ children }: { children?: React.ReactNode }) => (
    <CreateTaskModal {...{ show, toggleShow, ...props }}>
      {children}
    </CreateTaskModal>
  );

  return { toggleShow, Modal };
};

export default (props: PropsType) => {
  const [show, updateShow] = useState(false);
  const toggleShow = () => updateShow(!show);
  const { t } = useTranslation();

  return (
    <CreateTaskModal {...props} show={show} toggleShow={toggleShow}>
      <Button
        onClick={toggleShow}
        className={`float-right mb-1 mr-1 ${props.className || ""}`}
      >
        {t("task.new")}
      </Button>
    </CreateTaskModal>
  );
};
