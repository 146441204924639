import i18n, { Languages, isValidLanguage } from "@/common/languages/i18n";
import { useLocalStorage  } from "@uidotdev/usehooks";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

const ThemeContext = createContext<any>({});

export const ThemeSettings = {
  layout: {
    type: { vertical: "vertical", horizontal: "horizontal" },
    mode: { fluid: "fluid", boxed: "boxed", detached: "detached" },
    menuPosition: { scrollable: "scrollable", fixed: "fixed" },
  },
  theme: { light: "light", dark: "dark" },
  topbar: {
    theme: { light: "light", dark: "dark", brand: "brand" },
    logo: { hidden: "fullscreen", show: "" },
  },
  sidebar: {
    theme: { light: "light", dark: "dark", brand: "brand" },
    size: {
      default: "default",
      compact: "compact",
      condensed: "condensed",
      showOnHover: "sm-hover",
      full: "full",
      fullscreen: "fullscreen",
    },
    user: { show: true, hidden: false },
  },
  rightSidebar: { show: true, hidden: false },
};

type ThemeSettingsType = {
  layout: {
    type: string,
    mode: string,
    menuPosition: string,
  },
  theme: string,
  topbar: {
    theme: string,
    logo: string,
  },
  sidebar: {
    theme: string,
    size: string,
    user: boolean,
  },
  rightSidebar: boolean,
};

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useThemeContext must be used within an ThemeProvider");
  }
  return context;
}

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [currentLanguage, setCurrentLanguage] = useState(Languages.EN);

  const displaySettings = JSON.parse((localStorage['store'] || {})['display'] || '{}')

  const [settings, setSettings] = useLocalStorage<ThemeSettingsType>('display', {
    layout: {
      type: displaySettings['layout'] || ThemeSettings.layout.type.horizontal,
      mode: ThemeSettings.layout.mode.fluid,
      menuPosition: ThemeSettings.layout.menuPosition.fixed,
    },
    theme: displaySettings['theme'] || ThemeSettings.theme.light,
    topbar: {
      theme: ThemeSettings.topbar.theme.light,
      logo: ThemeSettings.topbar.logo.show,
    },
    sidebar: {
      theme: ThemeSettings.sidebar.theme.dark,
      size: ThemeSettings.sidebar.size.default,
      user: ThemeSettings.sidebar.user.hidden,
    },
    rightSidebar: ThemeSettings.rightSidebar.hidden,
  });

  const changeLanguage = useCallback((lang: string) => {
    if (isValidLanguage(lang)) {
      i18n.changeLanguage(lang);
      setCurrentLanguage(lang);
    }
  }, []);

  const updateSettings = useCallback(
    (newSettings: ThemeSettingsType) => {
      setSettings((prev) => ({ ...(prev ?? {}), ...(newSettings ?? {}) }));
    },
    [setSettings],
  );

  const updateLayout = useCallback(
    (newLayout: ThemeSettingsType['layout']) => {
      setSettings((prev) => ({
        ...(prev ?? {}),
        layout: { ...(prev ?? {}).layout, ...(newLayout ?? {}) },
      }));
    },
    [setSettings],
  );

  const updateTopbar = useCallback(
    (newTopbar: ThemeSettingsType['topbar']) => {
      setSettings((prev) => ({
        ...(prev ?? {}),
        topbar: { ...(prev ?? {}).topbar, ...(newTopbar ?? {}) },
      }));
    },
    [setSettings],
  );

  const updateSidebar = useCallback(
    (newSidebar: ThemeSettingsType['sidebar']) => {
      setSettings((prev) => ({
        ...(prev ?? {}),
        sidebar: { ...(prev ?? {}).sidebar, ...(newSidebar ?? {}) },
      }));
    },
    [setSettings],
  );

  return (
    <ThemeContext.Provider
      value={{
        changeLanguage,
        currentLanguage,
        settings,
        updateSettings,
        updateLayout,
        updateTopbar,
        updateSidebar,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}
