import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";
import { RemoveMembership_Mutation } from "./__generated__/RemoveMembership_Mutation.graphql";

const mutation = graphql`
  mutation RemoveMembership_Mutation(
    $input: MembershipDeleteInput!
    $connections: [ID!]!
  ) {
    deleteMembership(input: $input) {
      deletedUserIds @deleteEdge(connections: $connections)
    }
  }
`;

export const removeMembership = (
  id: string,
  clubId: string,
  connectionId: string,
) => {
  if (window.confirm("Are you sure you want to delete this task")) {
    const variables = {
      input: {
        ids: [id],
        clubId,
      },
      connections: [connectionId],
    };

    commitMutation<RemoveMembership_Mutation>(environment, {
      mutation,
      variables,
    });
  }
};

export default removeMembership;
