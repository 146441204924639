import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../Environment";
import { getFileMetadata, upload } from "../../services";
import { type UploadcreateMutation$data as ResponseType } from "./__generated__/UploadcreateMutation.graphql";

const mutation = graphql`
  mutation UploadcreateMutation(
    $filename: String!
    $byteSize: Int!
    $contentType: String!
    $checksum: String!
  ) {
    createUpload(
      input: {
        filename: $filename
        byteSize: $byteSize
        contentType: $contentType
        checksum: $checksum
      }
    ) {
      directUpload {
        blobId
        headers
        signedBlobId
        url
      }
    }
  }
`;

interface PropsType {
  file: File;
  onCompleted: (file: { signedBlobId: string; name: string }) => void;
}

const onCompleted = (props: PropsType) => (rawResponse: ResponseType | {}) => {
  const response = rawResponse as ResponseType;

  const {
    createUpload: {
      directUpload: { url, headers, signedBlobId },
    },
  } = response;
  return upload(url, JSON.parse(headers), props.file).then((r: any) => {
    props.onCompleted({ signedBlobId, name: props.file.name });
  });
};

export default (props: PropsType) => {
  getFileMetadata(props.file).then(
    ({ filename, byteSize, contentType, checksum }) => {
      return commitMutation(environment, {
        mutation,
        variables: {
          filename,
          byteSize,
          contentType,
          checksum,
        },
        onCompleted: onCompleted(props),
      });
    },
  );
};
