/**
 * @generated SignedSource<<d2474cb60f79c6f2b586b9a3655ec033>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Avatar_user$data = {
  readonly email: string;
  readonly id: string;
  readonly imageUrl: string | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "Avatar_user";
};
export type Avatar_user$key = {
  readonly " $data"?: Avatar_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"Avatar_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Avatar_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5855bfbeef4ec2ccbe67c7b1e177324a";

export default node;
