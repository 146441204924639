import { useState } from "react";
import { Modal as BSModal, Tab } from "react-bootstrap";
import { permissions } from "../../services/permissions";
import UpdateForm from "./UpdateForm";

const modalBodyStyle = {
  minHeight: "22rem",
};

type TaskType = {
  id: string;
  complete?: boolean;
  dueDate?: string;
  description?: string;
}

const UpdateModal = ({
  task,
  children,
}: {
  task: TaskType;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
}) => {
  const { Modal } = useUpdateModal({ task })

  return(<Modal>{children}</Modal>)
};

export const useUpdateModal = ({ task }: { task: TaskType }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const {
    task: { update: taskUpdate },
  } = permissions();

  const Modal = ({ children }: { children?: (t: { onClick: () => void }) => void }) => {
    if (!taskUpdate) {
      return <></>;
    }

    return (
      <>
        {children && children({ onClick: toggleShow })}
        <BSModal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={() => setShow(false)}
        >
          <Tab.Container defaultActiveKey="signIn">
            <BSModal.Header closeButton>Update Task</BSModal.Header>
            <BSModal.Body style={modalBodyStyle}>
              <UpdateForm task={task} onSubmit={() => setShow(false)} />
            </BSModal.Body>
          </Tab.Container>
        </BSModal>
      </>
    );
  }

  return { show, setShow, toggleShow, Modal }
}

export default UpdateModal;
