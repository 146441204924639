import { ThemeProvider } from "@/common/context";
import App from "@pages/App";
import SessionManager from "@pages/SessionManager";
import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";

import "./i18n";

/*
 * Redux
 */
import { Provider } from "react-redux";
import { Store } from "redux";
import configureStore, { AppStateType } from "./redux/store/Store";
import { loadStore, saveStore } from "./redux/store/localStorage";

import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

interface IProps {
  store: Store<AppStateType>;
}

const Root: React.FunctionComponent<IProps> = ({ store }) => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <SessionManager />
        <App />
      </ThemeProvider>
    </Provider>
  );
};

const store = configureStore({
  defaultStore: loadStore(),
});

store.subscribe(() => {
  saveStore(store.getState());
});

const BugsnagWrapper = ({ children }: { children: JSX.Element }) => {
  const bugsnagKey = process.env.REACT_APP_BUGSNAG_KEY_;
  if (!bugsnagKey) return children;

  const bugsnagClient = bugsnag(bugsnagKey || "");
  bugsnagClient.use(bugsnagReact, React);

  const BugsnagBoundary = bugsnagClient.getPlugin("react");

  return <BugsnagBoundary>{children}</BugsnagBoundary>;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <BugsnagWrapper>
    <Root store={store} />
  </BugsnagWrapper>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
