import { Formik } from "formik";
import Moment from "moment";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import updateTask from "./UpdateTask";

type PropsType = {
  task: {
    id: string;
    description?: string;
    complete?: boolean;
    dueDate?: string;
  };
  onSubmit: () => void;
};

const schema = yup.object().shape({
  role: yup.string(),
});

const UpdateForm = ({ task, onSubmit }: PropsType) => {
  const { id, dueDate, ...taskRest } = task;
  const renderComplete = taskRest.complete !== undefined
  const renderDueDate = dueDate !== undefined

  //             <>
  //               <div>{assignees && <Avatars users={assignees} max={2} />}</div>
  //               {assignmentCreate && <Assign assignable={{ id, type: 'task'}} assignees={assignees} />}
  //             </>

  return (
    <Formik
      initialValues={{
        ...taskRest,
        dueDate: Moment(dueDate).format("YYYY-MM-DD"),
      }}
      onSubmit={({ complete, description, dueDate }, actions) => {
        updateTask(
          {
            id,
            complete: renderComplete ? complete : undefined,
            description,
            dueDate: renderDueDate ? Moment(dueDate).toISOString() : undefined,
          },
          { onCompleted: onSubmit },
        );
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                type="text"
                placeholder="Description"
                isValid={touched.description && !errors.description}
                isInvalid={touched.description && !!errors.description}
              />
            </Form.Group>

            {renderDueDate && <Form.Group controlId="dueDate">
              <Form.Label>Due</Form.Label>
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.dueDate}
                type="date"
                placeholder="Description"
                isValid={touched.dueDate && !errors.dueDate}
                isInvalid={touched.dueDate && !!errors.dueDate}
              />
            </Form.Group>}

            {renderComplete && <Form.Group controlId="complete">
              <Form.Check
                label={"Complete"}
                checked={values.complete}
                onChange={handleChange}
                onBlur={handleBlur}
                type="checkbox"
                id="complete"
              />
            </Form.Group>}

            <div className="mt-2">
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default UpdateForm;
