import { Form, TextInput } from "@/components";
import AccountWrapper2 from "@/pagesExample/account/AccountWrapper2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import Button from "react-bootstrap/Button";
import PasswordResetRequest from "../Session/PasswordResetRequest";

type PropsType = {
  newUser?: boolean;
};

const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer footer-alt">
      <p className="text-muted">
        {t("Back to")}
        <Link to="/signin" className="text-muted ms-1">
          <b>{t("Log In")}</b>
        </Link>
      </p>
    </footer>
  );
};

const MainPage = ({ newUser = false }: PropsType) => {
  const [state, setState] = useState(false);

  const searchParams = useSearchParams()[0];

  const initialValues = {
    email: searchParams.get("email") || "",
  };

  const setSuccess = () => {
    setState(true);
  };

  const setRequesting = () => {
    setState(false);
  };

  const formSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Please enter password"),
  });

  return (
    <>
      <AccountWrapper2 bottomLinks={<BottomLink />}>
        <h4>{newUser ? "Account Confirmation" : "Reset Password"}</h4>
        {state ? (
          <>
            <p className="text-muted mb-4">
              {newUser ? "An account confirmation " : "A password reset "}link
              has been sent to your email address with instructions to{" "}
              {newUser ? " confirm your account" : " reset your password"}.
            </p>
            <div className="mb-0 text-center d-grid">
              <Button variant="primary" onClick={setRequesting}>
                <i className="mdi mdi-lock-reset" />
                &nbsp;Send another reset
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="text-muted mb-4">
              {newUser
                ? "Click to receive an email to confirm your account"
                : "Enter your email address and we'll send you an email with instructions to reset your password."}
            </p>
            <Form
              defaultValues={initialValues}
              schema={formSchema}
              onSubmit={({ email }) => {
                PasswordResetRequest(
                  {
                    email,
                    accountSetup: newUser,
                  },
                  { onSuccess: setSuccess },
                );
              }}
            >
              <TextInput
                name="email"
                containerClass="mb-3"
                label="Email Address"
                type="email"
                placeholder="Enter your email"
              />
              <div className="mb-0 text-center d-grid">
                <Button variant="primary" type="submit">
                  <i className="mdi mdi-lock-reset" />
                  &nbsp;{newUser ? "Confirm" : "Reset Password"}
                </Button>
              </div>
            </Form>
          </>
        )}
      </AccountWrapper2>
    </>
  );
};

export default MainPage;
