import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import MeetingForm from "./CreateMeetingForm";

interface PropsType {
  connectionId: string;
  className?: string;
}

const modalBodyStyle = {
  minHeight: "22rem",
};

const CreateMeetingModal = ({ className = "", connectionId }: PropsType) => {
  const [show, updateShow] = useState(false);
  const { t } = useTranslation();
  const toggleShow = () => updateShow(!show);
  return (
    <>
      <Button
        onClick={toggleShow}
        className={`float-right mb-1 mr-1 ${className}`}
      >
        {t("meeting.new")}
      </Button>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={toggleShow}
      >
        <Modal.Header closeButton>Create Meeting</Modal.Header>
        <Modal.Body style={modalBodyStyle}>
          <MeetingForm connectionId={connectionId} onSubmit={toggleShow} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateMeetingModal;
