import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  AssignMutation,
  AssignMutation$variables as InputType,
} from "./__generated__/AssignMutation.graphql";

interface ConfigType {
  onCompleted?: () => void;
}

const mutation = graphql`
  mutation AssignMutation(
    $assigneeId: ID
    $assignableId: ID!
    $assignableType: Assignables!
    $connections: [ID!]!
  ) {
    assignCreate(
      input: {
        assign: {
          assigneeId: $assigneeId
          assignableId: $assignableId
          assignableType: $assignableType
        }
      }
    ) {
      assignee
        @appendNode(connections: $connections, edgeTypeName: "UserEdge") {
        id
        name
        ...Avatar_user
      }
    }
  }
`;
export default (props: InputType, config?: ConfigType | undefined) => {
  commitMutation<AssignMutation>(environment, {
    mutation,
    variables: props,
    onCompleted: config?.onCompleted,
    updater: (store) => {
      const assignable = store.get(props.assignableId);
      const assignees = assignable?.getLinkedRecord("assignees");
      const currentCount = assignees?.getValue("totalCount");
      assignees?.setValue(Number(currentCount || 0) + 1, "totalCount");
    },
  });
};
