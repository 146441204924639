import { compact, map } from "lodash";
import prettyBytes from "pretty-bytes";
import { Card, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
// import { Badge, Container, Row, Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import CreateFileForm, {
  type FileLinkables,
} from "@/pages_old/Files/CreateFileForm";
import removeFileLink from "@/pages_old/Files/RemoveFileLink";
import { useExpired } from "@/services";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { permissions } from "@/services/permissions";

import { type Attachments_FileType$key as FileTypesType } from "./__generated__/Attachments_FileType.graphql";
export { type Attachments_FileType$key as AttachmentsType } from "./__generated__/Attachments_FileType.graphql";

type FileNodeType = Exclude<
  Exclude<
    Exclude<
      Exclude<
        Exclude<
          Exclude<
            Exclude<FileTypesType[" $data"], undefined>["edges"],
            null | undefined
          >[number],
          null | undefined
        >["fileLinks"],
        null | undefined
      >["edges"],
      null | undefined
    >[number],
    null | undefined
  >["node"],
  null | undefined
>;

type DownloadUrlType = FileNodeType["downloadUrl"];
type CategoryType = {
  id: string;
  code?: string | null;
  name?: string | null;
} | null;

const fragment = graphql`
  fragment Attachments_FileType on SuggestedFileTypeConnection {
    ...CreateFileModal_FileTypes
    edges {
      fileLinks {
        totalCount
        __id
        edges {
          node {
            id
            link
            downloadUrl {
              id
              url
              expiresAt
            }
            name
            contentType
            byteSize
          }
        }
      }
      node {
        id
        code
        name
      }
    }
  }
`;

const ExpiresWrapper = ({
  children,
  downloadUrl,
}: {
  downloadUrl: DownloadUrlType;
  children: (
    du: Exclude<DownloadUrlType, null | undefined>,
  ) => React.ReactElement;
}) => {
  if (!downloadUrl) {
    return <></>;
  }

  const { id, expiresAt } = downloadUrl || {};

  var expired = false;
  if (expiresAt) {
    expired = useExpired(expiresAt);
  }

  if (expired) {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 100, hide: 400 }}
        overlay={(props) => (
          <Tooltip id={`tooltip-${id}`} {...props}>
            Link has expired, refresh page to reauthenticate.
          </Tooltip>
        )}
      >
        {children(downloadUrl)}
      </OverlayTrigger>
    );
  }

  return children(downloadUrl);
};

const File = ({
  file,
  category,
  onDelete,
}: {
  file: FileNodeType;
  category?: CategoryType;
  onDelete?: (id: string) => void;
}) => {
  const { id, downloadUrl, name, contentType, byteSize, link } = file;
  const { fileLink: { delete: fileLinkDelete } } = permissions()

  return (
    <Card className="my-1 shadow-none border">
      <div className="p-2">
        <Row className="align-items-center">
          <div className="col-auto">
            <div className="avatar-sm">
              {contentType && (
                <span className="avatar-title rounded">
                  {(contentType || "").split("/")[1].substring(0,3)}
                </span>
              )}
              {link && <span className="avatar-title rounded">www</span>}
            </div>
          </div>
          <div className="col ps-0">
            <div className="text-muted fw-bold">{name}</div>
            {category && <p className="mb-0">{category.name}</p>}
            {byteSize && <p className="mb-0">{prettyBytes(byteSize)}</p>}
          </div>
          <div className="col-auto">
            {downloadUrl && (
              <ExpiresWrapper downloadUrl={downloadUrl}>
                {({ url }) => (
                  <Link
                    to={url}
                    target="_blank"
                    className="btn btn-link btn-lg text-muted"
                  >
                    <i className="ri-download-2-line"></i>
                  </Link>
                )}
              </ExpiresWrapper>
            )}
            {link && (
              <Link
                to={link}
                target="_blank"
                className="btn btn-link btn-lg text-muted"
              >
                <i className="uil-external-link-alt"></i>
              </Link>
            )}
            {fileLinkDelete && onDelete && (
              <Link
                to=""
                onClick={() => onDelete(id)}
                id="btn-Delete"
                className="btn btn-link text-danger btn-lg p-0"
              >
                <i className="uil uil-multiply"></i>
              </Link>
            )}
          </div>
        </Row>
      </div>
    </Card>
  );
};

const Attachments = (props: {
  title?: string;
  subtitle?: string;
  attachments: FileTypesType;
  linkable?: { id: string; type: FileLinkables };
}) => {
  const attachments = useFragment<FileTypesType>(fragment, props.attachments);

  const { linkable, title = 'Attachments', subtitle } = props;
  const hideCategory = attachments?.edges?.length === 1;

  return (
    <Card>
      <Card.Body>
        <h5 className="card-title mb-3">{ title }</h5>
        {subtitle && <h6 className="card-title mb-3">{ subtitle }</h6>}

        {linkable && (
          <CreateFileForm
            fileLinkable={linkable}
            edges={compact(attachments?.edges || [])}
          />
        )}

        {map(attachments.edges, (edge) => {
          return map(edge?.fileLinks.edges || [], (fileLink) => {
            const handleDelete = (id: string) => {
              removeFileLink(id, edge?.fileLinks.__id || "");
            };

            if (!fileLink?.node) {
              return <></>;
            }

            return (
              <File
                key={fileLink.node.id}
                file={fileLink.node}
                category={hideCategory ? undefined : edge?.node}
                onDelete={handleDelete}
              />
            );
          });
        })}
      </Card.Body>
    </Card>
  );
};

export default Attachments;
