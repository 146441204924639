import { graphql } from "babel-plugin-relay/macro";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import Invite from "./CreateMembership";
import removeMembership from "./RemoveMembership";
import UpdateModal from "./UpdateModal";

import { compact, map } from "lodash";
import { Container, Table } from "react-bootstrap";

import { Members_MembersConnection$key as UserConnectionType } from "./__generated__/Members_MembersConnection.graphql";

const { loadStore } = require("../../redux/store/localStorage");

const getCurrentClub = () => {
  try {
    return loadStore().currentClub || {};
  } catch (err) {
    return {};
  }
};

interface PropsType {
  users: UserConnectionType;
  clubId: string;
  className?: string;
}

const fragment = graphql`
  fragment Members_MembersConnection on ClubUserConnection {
    __id
    edges {
      id
      kind
      node {
        id
        email
        name
      }
    }
  }
`;

const Users = (props: PropsType) => {
  const users = useFragment<UserConnectionType>(fragment, props.users);
  const { clubId, className = "" } = props;
  const { edges = [], __id: connectionId } = users;
  const { t } = useTranslation();
  const {
    currentClub: { roleId },
  } = getCurrentClub();

  return (
    <Container className={`px-0 ${className}`}>
      <Invite connectionId={connectionId} clubId={clubId} />
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Kind</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {map(compact(edges), (edge) => {
              if (!edge) return <></>;
              const { id: edgeId, node, kind } = edge;
              if (!node) return <></>;
              const { id, email, name } = node;

              return (
                <tr key={id}>
                  <td>{name}</td>
                  <td>{t(`roles.${kind}`, "")}</td>
                  <td>{email}</td>
                  <td>
                    {roleId !== edgeId && (
                      <UpdateModal id={edgeId} role={kind}>
                        {({ onClick }) => (
                          <i onClick={onClick} className="bi bi-pencil"></i>
                        )}
                      </UpdateModal>
                    )}
                  </td>
                  {roleId === edgeId ? (
                    <td></td>
                  ) : (
                    <td
                      className="text-center"
                      onClick={() => removeMembership(id, clubId, connectionId)}
                    >
                      <i
                        className="bi bi-x danger"
                        style={{ color: "red" }}
                      ></i>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default Users;
