export default {
  month: {
    null: "No month",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  route: {
    projects: "Projects",
    organisation: "Org Chart",
    child_safety: "Child safety",
    health: "Health Check",
    risk: "Risk Register",
    tasks: "Tasks",
    meetings: "Meetings",
    users: "Members",
    files: "Files",
  },
  filter: {
    null: "All",
    unassigned: "Unassigned",
    mine: "Mine",
    overdue: "Overdue",
    incompleted: "Incomplete",
    completed: "Complete",
    admin: "Admins",
  },
  level: {
    null: "No Function",
    executive: "Executive Committee",
    committee: "Committee",
    subcommittee: "Subcommittee/Manager",
    officer: "Officer level",
    casual: "Casual level",
  },
  roles: {
    admin: "Admin",
    coordinator: "Editor",
    general: "Viewer",
  },
  task: {
    new: "Add New Task",
  },
  assignment: {
    title: "Responsible Person",
  },
  meeting: {
    new: "Add New Meeting",
    name: {
      commitee: "Committee",
      agm: "AGM",
      other: "Other",
    },
  },
  membership: {
    new: "Invite new member",
  },
  // notification: {
  //   'Comment': {
  //     create: '{{ actor_name }} made a comment on ${{ source_type }}: ${ {source_name }} {time}'
  //   }
  //   'Project':
  //   'Meeting':
  //   'Position':

  // }
};
