import { graphql } from "babel-plugin-relay/macro";
import { Component } from "react";
import { QueryRenderer } from "react-relay";
import environment from "../../Environment";

import Loading from "../Page/Loading";

import { HomePageQuery as HomePageQueryType } from "./__generated__/HomePageQuery.graphql";

const HomePageQuery = graphql`
  query HomePageQuery {
    viewer {
      ...HomePage_viewer @relay(mask: false)
    }
  }
`;

graphql`
  fragment HomePage_viewer on Viewer {
    ...RightNav_viewer
  }
`;

class HomePage extends Component {
  render() {
    return (
      <QueryRenderer<HomePageQueryType>
        environment={environment}
        query={HomePageQuery}
        variables={{}}
        render={(args) => {
          const { error, props } = args;
          if (error) {
            return <div>{error.message}</div>;
          } else if (props) {
            const { viewer } = props;
            if (!viewer) {
              return <></>;
            }
            return <>This is intentionally blank</>;
          }
          return <Loading />;
        }}
      />
    );
  }
}

export default HomePage;
