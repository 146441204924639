import { Formik } from "formik";
import { map } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { graphql } from "babel-plugin-relay/macro";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useMutation } from "react-relay";

import {
  InviteForm_Mutation,
  type MembershipRoleKindEnumType,
} from "./__generated__/InviteForm_Mutation.graphql";

const mutation = graphql`
  mutation InviteForm_Mutation(
    $input: MembershipCreateInput!
    $connections: [ID!]!
  ) {
    inviteUser(input: $input) {
      user
        @appendNode(connections: $connections, edgeTypeName: "ClubUserEdge") {
        id
        email
        name
      }
    }
  }
`;

const schema = yup.object().shape({
  email: yup.string().email().required("email required"),
  role: yup.string().required("role type is required"),
});

const roles: MembershipRoleKindEnumType[] = ["general", "coordinator", "admin"];

const InviteForm = ({
  connectionId,
  clubId,
  onSubmit,
}: {
  connectionId: string;
  clubId: string;
  onSubmit: () => void;
}) => {
  const [serverError, onServerError] = useState<string | null>(null);
  const [commit] = useMutation<InviteForm_Mutation>(mutation);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ email: "", name: "", role: roles[0] }}
      onSubmit={({ email, name, role }, actions) => {
        commit({
          variables: {
            input: {
              invite: {
                email,
                role,
                clubId,
                name,
              },
            },
            connections: [connectionId],
          },
          onError: (e) => {
            onServerError(e.message);
          },
          onCompleted: (r, e) => {
            onSubmit();
          },
        });
      }}
      validationSchema={schema}
      render={({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        status,
        isSubmitting,
      }) => {
        console.log("errors", errors);
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder="Name"
                isValid={touched.name && !errors.name}
                isInvalid={touched.name && !!errors.name}
              />

              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="pt-1">
              <Form.Control
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                placeholder="Email"
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="role" className="pt-1">
              <Form.Select
                aria-label="Default select example"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.role}
                isValid={touched.role && !errors.role}
                isInvalid={touched.role && !!errors.role}
              >
                {map(roles, (role) => (
                  <option key={role} value={role}>
                    {t(`roles.${role}`)}
                  </option>
                ))}
              </Form.Select>

              <Form.Control.Feedback
                type="invalid"
                className={touched.role && errors.role ? "d-block" : ""}
              >
                {errors.role}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              {serverError}
            </Form.Control.Feedback>

            <Form.Group controlId="role" className="pt-1">
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                className="pt-1"
              >
                Submit
              </Button>
              <div className="pt-1">
                {!!status && <Alert variant="danger">{status}</Alert>}
              </div>
            </Form.Group>
          </Form>
        );
      }}
    />
  );
};

export default InviteForm;
