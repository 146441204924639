import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  PasswordResetMutation,
  type PasswordResetMutation$data as ResponseType,
} from "./__generated__/PasswordResetMutation.graphql";

export type onSuccessResponseType = ResponseType;

interface PasswordResetType {
  name?: string;
  password: string;
  token: string;
  onSuccess: (result: ResponseType) => void;
}

const mutation = graphql`
  mutation PasswordResetMutation($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      status
    }
  }
`;

const passwordReset = ({ onSuccess, ...input }: PasswordResetType) => {
  commitMutation<PasswordResetMutation>(environment, {
    mutation,
    variables: { input },
    onCompleted: onSuccess,
  });
};

export default passwordReset;
