import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";

import environment from "../../Environment";

import {
  UpdatePosition_Mutation,
  type PositionLevel,
  type PositionUpdateInputType
} from "./__generated__/UpdatePosition_Mutation.graphql";

const mutation = graphql`
  mutation UpdatePosition_Mutation($input: PositionUpdateInput!) {
    updatePosition(input: $input) {
      position {
        ...PositionsPage_position @relay(mask: false)
      }
    }
  }
`;

export default (position: PositionUpdateInputType, config?: { onCompleted?: () => void }) => {
  const variables = {
    input: {
      position,
    },
  };

  commitMutation<UpdatePosition_Mutation>(environment, {
    mutation,
    variables,
    onCompleted: config?.onCompleted,
  });
};
