import { authApi } from "@/common/api";
import { useAuthContext, useNotificationContext } from "@/common/context";
import type { User } from "@/types";
import { AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

export const loginFormSchema = yup.object({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter email"),
  password: yup.string().required("Please enter password"),
});

export type LoginFormFields = yup.InferType<typeof loginFormSchema>;

export default function useLogin() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, saveSession } = useAuthContext();
  const { showNotification } = useNotificationContext();

  const redirectUrl = useMemo(
    () =>
      location.state && location.state.from
        ? location.state.from.pathname
        : "/",
    [location.state],
  );

  const login = async (values: LoginFormFields) => {
    setLoading(true);
    try {
      const res: AxiosResponse<User> = await authApi.login(values);
      if (res.data.token) {
        saveSession({ ...(res.data ?? {}), token: res.data.token });
        navigate(redirectUrl);
      }
    } catch (error: any) {
      showNotification({ message: error.toString(), type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return { loading, login, redirectUrl, isAuthenticated };
}
