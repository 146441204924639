import Moment from "moment";
import { useEffect, useState } from "react";

const useExpired = (expiresAt: string) => {
  const time = Moment(expiresAt).unix() * 1000 - new Date().getTime();
  const [expired, setExpired] = useState(time < 0);

  useEffect(() => {
    let timer = setTimeout(() => {
      setExpired(true);
    }, time);
    return () => {
      clearTimeout(timer);
    };
  }, [time]);
  return expired;
};

export default useExpired;
